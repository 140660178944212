import ModernFormInputComponent from '../../components/footer/ModernFormInputComponent'
import { ErrorMessage, ErrorMessageWrapper, Question } from './widget.sytled'

const ModernFormUI = ({
  errorMessage,
  countryCode,
  options,
  customisation,
  fieldType,
  onInputSubmit,
  apiKeyName,
  question,
  prevQuestionButtonClick,
  moveToPrevQuestionButtonDisabled,
  moveToNextQuestionButtonDisabled,
  isThisLastQuestion,
  formSubmitted,
  attribute
}) => {
  return (
    <div>
      <div style={{ padding: '0 12px' }}>
        {/* <QuestionNumber>
          {questionIndex + 1} <i className="bi bi-arrow-right-short"></i>
        </QuestionNumber>{' '} */}
        <Question>{question.question}</Question>
      </div>
      <ModernFormInputComponent
        {...{
          countryCode,
          options,
          customisation,
          fieldType,
          onInputSubmit,
          apiKeyName,
          prevQuestionButtonClick,
          moveToPrevQuestionButtonDisabled,
          moveToNextQuestionButtonDisabled,
          isThisLastQuestion,
          formSubmitted,
        }}
        question={question}
      />
      {errorMessage && (
        <ErrorMessageWrapper>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </ErrorMessageWrapper>
      )}
    </div>
  )
}

export default ModernFormUI

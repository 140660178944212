import styled from 'styled-components'
import { hexToLuma } from '../widget/helper'
import { Container, Form, Row } from 'react-bootstrap';

export const NormalFormContainer = styled.div`
  box-sizing: border-box;
  font-family: inter;
  font-size: 16px;
  height: 100%;
  width: 100%;
`;
export const Conversation = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  background-color: ${(props) => props.theme.background};
`;
export const AppFrame = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 700px;
  margin: 0px auto;
  padding-top: 16px;
  color: rgb(76, 76, 76);
  background: transparent;
  border-radius: 3px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  z-index: 1;
`

export const AppBackground = styled.div`
  display: inline-block;
  line-height: 0;
  opacity: 1;
  background-color: white;
  // background-color: ${(props) => props.theme.background};
  background-image: url(${(props) => props.theme.backgroundImage});
  background-position: center top;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
  }
`

export const NFContainer = styled(Container)`
    z-index:1;
    max-width: 850px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    // overflow: auto;
    padding: 0px 0px;
    // background-color: ${(props) => props.theme.background};
`;

export const NFFormHolder = styled.div`
  z-index:1;
  background-color: ${(props) => props.theme.background};
  width: 100vw;
  height: 100vh;
  overflow: auto;

`


export const NFFormConatiner = styled.div`
  background-color: ${(props) => props.theme.background};
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    padding: 20px 20px;
    // max-height: calc(100%);
    // border-radius: 0px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    padding: 30px 30px;
    // max-height: calc(100%);
    // border-radius: 0px;
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    padding: 40px 40px;
    // max-height: calc(100vh - 0px);
    // border-radius: 0px;
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    padding: 50px 50px;
    // max-height: calc(100vh - 0px);
    // border-radius: 10px;
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    padding: 60px 60px;
    // max-height: calc(100vh - 0px);
    // border-radius: 10px;

  }
`;

export const NFFromHeader = styled.div`
  margin-bottom: 30px;

  .content-header{
    color : ${(props) => hexToLuma(props.theme.background)};
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
    letter-spacing: -0.08px;
    font-size: 20px !important;
    padding-bottom: 12px;

  }

  .content-wrapper{
    color : ${(props) => hexToLuma(props.theme.background)};
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height:  25px ;
    letter-spacing: -0.08px;
    font-size: 16px !important;
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {

  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {

  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {

  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {

  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    
  }

`;
export const NFHeaderMedia = styled.img`
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 20px;


  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    height: 100px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    height: 150px;
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    height: 200px;
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    height: 200px;
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    height: 200px;
  }
`;

export const NFContainerHr = styled.hr`
width: 100%;
  // border-bottom: 2px solid ${(props) => props.theme.question};
`;

export const NFRow = styled(Row)`
  margin-top: 40px;
  width: 100%;

   
`;

export const QuestionLabel = styled(Form.Label)`
  color:  ${(props) => props.theme.question};
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  letter-spacing: -0.08px;
  margin-bottom: 15px;
`;

export const SubmitButton = styled.button`
  color: ${(props) => hexToLuma(props.theme.button)};
  outline: none;
  border: none;
  display: inline-flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: ${(props) => props.theme.button};;

  /* Button */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.09);

`


export const PoweredByWrapper = styled.div`
    position: relative;
    font-size: 12px;
    text-align: left;
    background: transparent;
    padding: 0px 0px 8px;
    // border-bottom: 1px solid rgba(61, 61, 61, 0.1);
    box-sizing: inherit;
    margin: 30px 0px 0px 0px;

    
    padding:7px 10px;
    font-size: 12px;
    background-color:${props => props.theme.question};
    color: ${props => hexToLuma(props.theme.question)};
    border-radius: 4px;
`;

export const PoweredByA = styled.a`
  font-size: 12px;
  // background-color:${props => props.theme.question};
  // color: ${props => hexToLuma(props.theme.question)};
  text-decoration: none;

  &:hover {
    color: ${props => hexToLuma(props.theme.question)};
    opacity: 0.9
  }
`
export const ErrorMessageWrapper = styled.div`
  padding: 12px;
`

export const ErrorMessage = styled.div`
  padding: 0px 12px 0px 12px;
  line-height: 40px;
  font-size: 13px;
  background: #fff;
  color: #d63031;
`
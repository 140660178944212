import React, { useState, useEffect, useCallback } from "react";
import {
    RootMessageList, MessageListScroller, MessageListWrapper,
    ConversationMessageList, MessageContainer, QuestionWrapper, InnerQuestionWrapper, AnswerWrapper, AvatarWrapper,
    InnerAnswerWrapper, ErrorMessageWrapper, ErrorMessage
} from "./widget.sytled";
import "./styles.scss";


const usrIcon = <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
</svg>;



export default function MessageList(props) {
    const [referenceDiv, setReferenceDiv] = useState(null);

    const scrollToBottom = useCallback(() => {
        if (referenceDiv) {
            const scrollHeight = referenceDiv.scrollHeight;
            const height = referenceDiv.clientHeight;
            const maxScrollTop = scrollHeight - height;
            referenceDiv.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        }
    }, [referenceDiv]);

    useEffect(() => {
        scrollToBottom();
    }, [props.conversation, scrollToBottom, props.errorMessage])

    const renderTyping = () => {
        return (
            <div className="typing-wrapper"><div className="dot-container"><span class="each-dot"></span><span class="each-dot"></span><span class="each-dot"></span></div></div>
        );
    }
    return <ConversationMessageList>
        <RootMessageList>

            <MessageListScroller ref={(div) => setReferenceDiv(div)}>

                <MessageListWrapper >
                    {props.showTyping &&
                        <MessageContainer key={-1}><QuestionWrapper>{renderTyping()}</QuestionWrapper></MessageContainer>
                    }
                    {props.conversation.map((each, index) => {
                        return <MessageContainer key={index}>
                            {each.from === "bot" ?
                                <>
                                    {props.currentQuestionId === each._id && <AvatarWrapper>
                                        {usrIcon}
                                    </AvatarWrapper>}
                                    <QuestionWrapper>
                                        <InnerQuestionWrapper>
                                            <div>{each.message}</div>
                                        </InnerQuestionWrapper>
                                    </QuestionWrapper>
                                </>
                                :
                                <AnswerWrapper>
                                    <InnerAnswerWrapper>
                                        <div>{each.message}</div>
                                    </InnerAnswerWrapper>
                                </AnswerWrapper>
                            }

                        </MessageContainer>
                    })}
                </MessageListWrapper>
                {props.errorMessage && <ErrorMessageWrapper>
                    <ErrorMessage>
                        {props.errorMessage}
                    </ErrorMessage>
                </ErrorMessageWrapper>}
            </MessageListScroller>
        </RootMessageList>
    </ConversationMessageList>

}
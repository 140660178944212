import * as classNames from "classnames";
import * as React from "react";
import { hexToLuma } from "../../widget/helper";
import Notifier from "./Notifier";

function Launcher(props) {
  const {
    isMessengerVisible,
    onClick,
    uiOptions,
    onContentClick,
    showNotifier,
    introBubble,
    setShowNotifier
  } = props;

  const clickHandler = () => {
    onClick(isMessengerVisible);
  };

  const launcherClasses = classNames("sales-simplify-launcher", {
    close: isMessengerVisible
  });

  const { color, logo } = uiOptions;

  const renderNotifier = () => {
    if (!introBubble) {
      return null;
    }
    return <Notifier isVisible={showNotifier} onContentClick={onContentClick} content={introBubble} onClose={() => setShowNotifier(false)} />;
  };

  return (
    <>
      <div
        className={launcherClasses}
        onClick={clickHandler}
        style={{
          backgroundColor: color,
          color: hexToLuma(color),
          backgroundImage: logo ? `url(${logo})` : "",
          backgroundSize: logo ? "" : "20px"
        }}
      >
        {!isMessengerVisible && <i className="bi bi-chat-left-dots"></i>}
      </div>
      {renderNotifier()}
    </>
  );
}

export default Launcher;

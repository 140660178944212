import React, { useState } from 'react'
import styled from 'styled-components'
import 'react-phone-input-2/lib/style.css'
import { hexToLuma, setOpacity } from '../../containers/widget/helper'
import './styles.css'
import {
  MoveButton,
  MoveButtonsWrapper,
} from '../../containers/widget/widget.sytled'
import CustomInput from '../custom-input'

export const ConversationFooter = styled.div`
  box-sizing: inherit;
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
  position: relative;
  overflow: inherit;
  transition: max-height 500ms cubic-bezier(0.19, 1, 0.22, 1) 0ms,
    bottom 400ms cubic-bezier(0.19, 1, 0.22, 1) 200ms,
    opacity 400ms ease-out 180ms;
  bottom: 0px;
  opacity: 1;
  max-height: 336px; //74px;//var(--max-height);
`

export const UserInputContainer = styled.div`
  width: 100%;
  box-sizing: inherit;
`

export const UserInputWrapper = styled.div`
  padding: 0px 16px 16px;
  box-sizing: inherit;
`
export const InputBox = styled.div`
  box-sizing: inherit;
  opacity: 1;
  transition: opacity 0.3s ease 0s;
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: inherit;
`

export const SkipButton = styled.button`
  display: none;
  // display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 40px;
  border-radius: 4px;
  transition: opacity 0.2s ease 0s;
  border: 1px solid transparent;
  padding: 0px 10px;
  margin-right: 8px;
  color: ${(props) => props.theme.answer};
  background: ${(props) => setOpacity(props.theme.answer, 0.1)};
`
export const SkipButton2 = styled.span`
  width: 40px;
  height: 40px;
`
export const SendButton = styled.button`
  align-self: start;
  border-radius: 4px;
  font-size: 1.2rem;
  margin-top: 16px;
  padding: 0.2em 0.7em;
  transition: opacity 0.2s ease 0s;
  background: ${(props) => props.theme.button};
  border: 1px solid ${(props) => props.theme.button};
  color: ${(props) => hexToLuma(props.theme.button)};

  &:hover {
    opacity: 0.7;
  }
  &:focus {
    border-color: ${(props) => hexToLuma(props.theme.background)};
  }

  &:disabled {
    opacity: 0.2;
  }

  @media only screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
`
export const Input = styled.input`
  background: transparent;
  color: ${(props) => props.theme.answer};
  font-size: 16px;
  font-family: inherit;
  display: flex;
  height: 40px;
  width: 100%;
  padding: 0px 12px;
  box-shadow: ${(props) => props.theme.answer} 0px 0px 0px 1px inset;
  border: none;
  border-radius: 4px;
  line-height: 20px;
  outline: none;
  transition: all 0.3s ease 0s;
  appearance: none;
`
export const InputInner = styled.div`
  display: flex;
  flex: 1 1 0%;
  box-sizing: inherit;
`

export const ChoiceOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 320px;
`

export const ListScroller = styled.div`
  overflow-x: auto;
  display: flex;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 24px;
  scroll-padding-right: 24px;
  padding-left: 16px;
  padding-right: 16px;
`

export const ListRootColumn = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 100%;
`
export const ListRootRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  max-width: 100%;
`
export const ListButton = styled.button`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  margin: 0px 0px 8px;
  padding: 12px 15px;
  line-height: 20px;
  // min-height: 44px;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  color: ${(props) => props.theme.answer};
  transition: all 0.2s ease 0s;
  cursor: pointer;
  width: fit-content;
  max-width: 340px;
  border: none;
  box-shadow: ${(props) => props.theme.answer} 0px 0px 0px 1px inset;
  background: ${(props) => setOpacity(props.theme.answer, 0.1)};
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  &:hover {
    background: ${(props) => setOpacity(props.theme.answer, 0.15)};
  }
`

export const ListRowButtons = styled.button`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px 0px 0px 8px;
  padding: 0px 12px;
  line-height: 20px;
  min-height: 44px;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  color: ${(props) => props.theme.answer};
  transition: all 0.2s ease 0s;
  cursor: pointer;
  width: fit-content;
  max-width: 340px;
  border: none;
  box-shadow: ${(props) => props.theme.answer} 0px 0px 0px 1px inset;
  background: ${(props) => setOpacity(props.theme.answer)};
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  flex: 0 0 auto;
`

export default function ModernFormInputComponent(props) {
  const [inputData, setInputData] = useState('')

  const onInputClick = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      props.onInputSubmit(inputData)
    }
  }
  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    props.onInputSubmit(inputData)
  }

  const nextButtonProps = {
    formSubmitted: props.formSubmitted,
    handleClick,
    submitButton: props.isThisLastQuestion,
  }

  const prevQuestionButtonClick = () => {
    props.prevQuestionButtonClick()
  }
  const nextQuestionButtonClick = () => {
    props.onInputSubmit(inputData)
  }
  return (
    <>
      <ConversationFooter>
        <UserInputContainer>
          <UserInputWrapper>
            <InputBox>
              {props.question ?
                <InputWrapper style={{ "--answer": props.customisation.answer }}>
                  <CustomInput
                    attribute={props.question.attributeData}
                    value={inputData}
                    setValue={(value) => setInputData(value)}
                    onKeyDown={onInputClick}
                    countryCode={props.countryCode ? props.countryCode.toLowerCase() : "us"}
                  />
                  <NextButton {...nextButtonProps} />
                </InputWrapper> :
                <></>}
            </InputBox>
          </UserInputWrapper>
        </UserInputContainer>
      </ConversationFooter>
      <MoveButtonsWrapper>
        <MoveButton
          onClick={prevQuestionButtonClick}
          disabled={props.moveToPrevQuestionButtonDisabled}
          type="button"
        >
          <i className="bi bi-chevron-up"></i>
        </MoveButton>
        <MoveButton
          onClick={nextQuestionButtonClick}
          disabled={props.moveToNextQuestionButtonDisabled}
          type="button"
        >
          <i className="bi bi-chevron-down"></i>
        </MoveButton>
      </MoveButtonsWrapper>
    </>
  )
}

const NextButton = ({ handleClick, submitButton, formSubmitted }) => {
  return (
    <SendButton
      disabled={formSubmitted}
      onClick={(e) => handleClick(e)}
      type="button"
    >
      {submitButton ? (
        <span>Submit</span>
      ) : (
        <>
          <span>OK</span> <i className="bi bi-check2"></i>
        </>
      )}
    </SendButton>
  )
}

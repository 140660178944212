import React from "react";
import styled from "styled-components";
import SelectDropdown from "../../../../components/footer/dropdown";

export const InputInner = styled.div`
  display: flex;
  flex: 1 1 0%;
  box-sizing: inherit;
`;

export default function StatusInput({ attribute, value, setValue, type, onKeyDown }) {
    const [options, setOptions] = React.useState([]);

    React.useEffect(() => {
        if (attribute.options) {
            setOptions(attribute.options);
        }
    }, [attribute]);

    return <InputInner onKeyDown={onKeyDown}>
        <SelectDropdown
            type="status"
            label={attribute.name}
            list={options}
            value={value}
            setValue={(value) => { setValue(value); }}
        />
    </InputInner>
}
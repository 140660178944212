import React from "react";
import styled from "styled-components";

export const InputInner = styled.div`
  display: flex;
  flex: 1 1 0%;
  box-sizing: inherit;
`;

const SingleStarContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 10px;
    padding: 2px 0px;
    cursor: pointer;
    color: ${props => props.theme.answer}55;
  
    & > i.checked {
        color: ${props => props.theme.answer};
    }
`;
export default function RatingInput({ attribute, value, setValue, type, onKeyDown }) {
    const [temp, setTemp] = React.useState(0);
    const [defaultValue, setDefaultValue] = React.useState(0);

    React.useEffect(() => {
        if (value) {
            setDefaultValue(value);
            setTemp(value);
        } else {
            setDefaultValue(0);
            setTemp(0);
        }
    }, [value]);

    const onValueChange = (val) => {
        setValue(val);
        setDefaultValue(val);
        setValue(val);

    }



    return (
        <InputInner onKeyDown={onKeyDown}>
            <SingleStarContainer >
                <i class={`bi bi-star-fill ${temp >= 1 ? "checked" : ""}`}
                    onMouseEnter={() => setTemp(1)}
                    onMouseLeave={() => setTemp(defaultValue)}
                    onClick={() => onValueChange(1)}
                ></i>
                <i class={`bi bi-star-fill ${temp >= 2 ? "checked" : ""}`}
                    onMouseEnter={() => setTemp(2)}
                    onMouseLeave={() => setTemp(defaultValue)}
                    onClick={() => onValueChange(2)}
                ></i>
                <i class={`bi bi-star-fill ${temp >= 3 ? "checked" : ""}`}
                    onMouseEnter={() => setTemp(3)}
                    onMouseLeave={() => setTemp(defaultValue)}
                    onClick={() => onValueChange(3)}
                ></i>
                <i class={`bi bi-star-fill ${temp >= 4 ? "checked" : ""}`}
                    onMouseEnter={() => setTemp(4)}
                    onMouseLeave={() => setTemp(defaultValue)}
                    onClick={() => onValueChange(4)}
                ></i>
                <i class={`bi bi-star-fill ${temp >= 5 ? "checked" : ""}`}
                    onMouseEnter={() => setTemp(5)}
                    onMouseLeave={() => setTemp(defaultValue)}
                    onClick={() => onValueChange(5)}
                ></i>
            </SingleStarContainer>
        </InputInner>
    );

}
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
  WidgetContainer,
  AppBackground,
  AppFrame,
  Conversation,
} from './widget.sytled'
import PoweredBy from '../../components/powered-by'
import Footer from '../../components/footer'
import MessageList from './message-list'
import { useParams } from 'react-router-dom'
import {
  getLeadbotDetails,
  saveResponse,
  getGeoLocation,
} from './api.widget'
import { checkMessageType, getOptions, validate } from './helper'
import { ThemeProvider } from 'styled-components'
import WelcomeScreen from '../welcome-screen'
import ThankYouScreen from '../thankyou-screen'
import NotAvailable from '../../components/not-available/not-available.landing-page'
import Loading from '../../components/loading/loading'
import { attributeTypes } from '../../components/footer/constants'

export default function Widget(props) {
  const [leadBotId, setLeadBotId] = useState('')
  const [conversation, setConversation] = useState([])
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false)
  const [showChatHistoryScreen, setShowChatHistoryScreen] = useState(false)
  const [showThankYouScreen, setShowThankYouScreen] = useState(false)

  const [welcomeScreenData, setWelcomeScreenData] = useState({})
  const [questions, setQuestions] = useState([])
  const [thankYouScreenData, setThankYouScreenData] = useState({})
  const [customisation, setCustomisation] = useState({})
  const [showBranding, setShowBranding] = useState(true)

  const [isActive, setIsActive] = useState(false)
  const [loading, setLoading] = useState(true)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentQuestionId, setCurrentQuestionId] = useState('')
  const [response, setResponse] = useState({})
  const [theme, setTheme] = useState({})
  const [errorMessage, setErrorMessage] = useState('')
  const [countryCode, setCountryCode] = useState('us')
  const [showTyping, setShowTyping] = useState(false)

  const params = useParams()

  useEffect(() => {
    if (params.id) setLeadBotId(params.id)
  }, [params.id])

  useEffect(() => {
    if (props.leadBotId) setLeadBotId(props.leadBotId)
  }, [props.leadBotId])

  useEffect(() => {
    async function fetchData() {
      try {
        const leadBotData = await getLeadbotDetails(leadBotId)
        if (!_.isEmpty(leadBotData.data)) {
          setIsActive(leadBotData.data.isEnabled)
          if (leadBotData.data.name) {
            document.title = leadBotData.data.name
          }

          const welcomeScreen = leadBotData.data.welcomeScreen
          setWelcomeScreenData(welcomeScreen)
          setThankYouScreenData(leadBotData.data.thankYouScreen)
          setCustomisation(leadBotData.data.customization)
          setQuestions(leadBotData.data.questionsScreen)
          setTheme({
            question: leadBotData.data.customization.question,
            answer: leadBotData.data.customization.answer,
            button: leadBotData.data.customization.button,
            background: leadBotData.data.customization.background,
            backgroundImage: leadBotData.data.customization.backgroundImage,
          })
          if (welcomeScreen.isEnabled) {
            setShowWelcomeScreen(true)
            setShowChatHistoryScreen(false)
            setShowThankYouScreen(false)
          } else {
            onGetStarted()
          }

          if (
            typeof leadBotData.data.customization.isBrandingEnabled ===
            'boolean' &&
            !leadBotData.data.customization.isBrandingEnabled
          ) {
            setShowBranding(false)
          } else {
            setShowBranding(true)
          }

          setLoading(false)
        } else {
          setLoading(false)
          setIsActive(false)
        }
      } catch (err) {
        setLoading(false)
        setIsActive(false)
        console.log('err', err)
      }
    }
    if (leadBotId) fetchData()
  }, [leadBotId])

  useEffect(() => {
    async function geoLocation() {
      try {
        const leadBotData = await getGeoLocation()
        if (leadBotData.country_code) {
          setCountryCode(leadBotData.country_code)
        }
      } catch (err) {
        console.log(err)
      }
    }
    geoLocation()
  }, [])

  useEffect(() => {
    if (
      questions.length > 0 &&
      questions[currentIndex] &&
      conversation.length === 0
    ) {
      const _con = []
      _con.push({
        _id: questions[currentIndex]._id,
        message: questions[currentIndex].question,
        messageType: checkMessageType(questions, currentIndex),
        from: 'bot',
        options: getOptions(questions, currentIndex),
      })
      setConversation(_con)
      setCurrentQuestionId(questions[currentIndex]._id)
    }
  }, [questions, currentIndex, conversation])

  const onGetStarted = () => {
    setShowWelcomeScreen(false)
    setShowChatHistoryScreen(true)
    setShowThankYouScreen(false)
  }


  const getAPIKeyName = (question) => {
    let keyName = ''
    for (const each of question.path) {
      if (keyName) keyName += '_'
      keyName += each.attributeId;
    }
    return keyName;
  }
  const updateResponse = (input, index, extra, newResponse) => {
    const question = questions[index]
    if (!question) return newResponse;
    if (_.isEmpty(question.attributeData)) return newResponse;
    const customResponse = { ...newResponse };

    const keyname = getAPIKeyName(question);

    const attribute = question.attributeData;

    switch (attribute.inputType) {
      case attributeTypes.TEXT:
      case attributeTypes.NUMBER:
      case attributeTypes.CURRENCY:
      case attributeTypes.RATING:
      case attributeTypes.DOMAIN:
      case attributeTypes.FILE:
      case attributeTypes.AI_WIZARD:
      case attributeTypes.EMAIL_ADDRESS:
      case attributeTypes.PHONE_NUMBER:
      case attributeTypes.RECORD_REFERENCE:
      case attributeTypes.ACTOR_REFERENCE:
      case attributeTypes.CHECKBOX:
        if (attribute.isMultiSelect) customResponse[`${keyname}`] = [input];
        else customResponse[`${keyname}`] = input;
        break;
      case attributeTypes.DATE:
      case attributeTypes.TIMESTAMP:
        if (attribute.isMultiSelect) customResponse[`${keyname}`] = [new Date(input).getTime()];
        else customResponse[`${keyname}`] = new Date(input).getTime();
        break;
      case attributeTypes.STATUS:
      case attributeTypes.SELECT:
      case attributeTypes.MULTISELECT:
        if (attribute.isMultiSelect) {
          if (Array.isArray(input)) {
            customResponse[`${keyname}`] = input.map((each) => each._id);
          }
          else customResponse[`${keyname}`] = input ? [input._id] : [];
        }
        else {
          if (Array.isArray(input)) customResponse[`${keyname}`] = input[0]._id;
          else customResponse[`${keyname}`] = input ? input._id : '';
        }
        break;
      default:
        if (attribute.isMultiSelect) customResponse[`${keyname}`] = [input];
        else customResponse[`${keyname}`] = input;
        break;
    }
    setResponse(customResponse)
    return customResponse
  }

  const handleHiddenFieldData = (index, _response) => {
    const q = questions[index + 1]
    if (q && q.isHidden) {
      const response = updateResponse(q.defaultAnswer, index + 1, '', _response)
      return handleHiddenFieldData(index + 1, response)
    } else {
      return { index, _response }
    }
  }

  const onInputSubmit = async (input, extra) => {
    const conversationMessage = []
    let _response = { ...response },
      defaultCurrentIndex = currentIndex

    const validationResult = validate(questions, currentIndex, input)
    if (!validationResult.status) {
      setErrorMessage(validationResult.message)
      return
    }

    if (input) {
      let updatedMessage = "";
      if (_.isArray(input) && input.length > 0) {
        updatedMessage = input.map(each => each.name).join(", ");
      }
      else if (_.isObject(input)) {
        updatedMessage = input?.name;
      }
      else if (_.isBoolean(input)) {
        updatedMessage = input ? "Yes" : "No";
      }
      else {
        updatedMessage = input;
      }
      conversationMessage.push({
        _id: '',
        message: updatedMessage,
        messageType: 'text-message',
        from: 'user',
        time: new Date(),
        options: [],
      })

      _response = updateResponse(input, currentIndex, extra, _response)
    }

    if (questions.length - 1 > currentIndex) {
      let updated = handleHiddenFieldData(currentIndex, _response)
      _response = updated._response
      defaultCurrentIndex = updated.index
    }

    if (questions.length - 1 > defaultCurrentIndex) {
      conversationMessage.unshift({
        _id: questions[defaultCurrentIndex + 1]._id,
        message: questions[defaultCurrentIndex + 1].question,
        messageType: checkMessageType(questions, defaultCurrentIndex + 1),
        from: 'bot',
        options: getOptions(questions, defaultCurrentIndex + 1),
      })
      setCurrentQuestionId(questions[defaultCurrentIndex + 1]._id)
    } else {
      //end of flow
      const updatedResponse = { ..._response };
      saveResponse(leadBotId, updatedResponse)
      if (thankYouScreenData.isEnabled) {
        setShowWelcomeScreen(false)
        setShowChatHistoryScreen(false)
        setShowThankYouScreen(true)
      }
    }
    setErrorMessage('')
    setCurrentIndex(defaultCurrentIndex + 1)

    //typing logic
    if (conversationMessage.length === 2) {
      const oldConv = [...conversation]
      setShowTyping(true)
      setConversation([conversationMessage[1], ...oldConv])
      setTimeout(() => {
        setConversation([...conversationMessage, ...oldConv])
        setShowTyping(false)
      }, 1000)
    } else {
      setConversation([...conversationMessage, ...conversation])
    }
  }

  return (
    <WidgetContainer>
      {loading ? (
        <Loading />
      ) : !isActive ? (
        <NotAvailable />
      ) : (
        <ThemeProvider theme={theme}>
          <Conversation>
            {showWelcomeScreen && (
              <WelcomeScreen
                onGetStarted={onGetStarted}
                data={welcomeScreenData}
              />
            )}
            {showChatHistoryScreen && (
              <AppFrame>
                {showBranding && <PoweredBy />}
                <MessageList
                  showTyping={showTyping}
                  errorMessage={errorMessage}
                  conversation={conversation}
                  questions={questions}
                  customisation={customisation}
                  currentQuestionId={currentQuestionId}
                  setConversation={setConversation}
                />
                <Footer
                  countryCode={countryCode}
                  question={questions[currentIndex]}
                  options={getOptions(questions, currentIndex)}
                  customisation={customisation}
                  fieldType={checkMessageType(questions, currentIndex)}
                  onInputSubmit={onInputSubmit}
                />
              </AppFrame>
            )}
            {showThankYouScreen && (
              <ThankYouScreen
                showBranding={showBranding}
                data={thankYouScreenData}
              />
            )}
            <AppBackground />
          </Conversation>
        </ThemeProvider>
      )}
    </WidgetContainer>
  )
}



import React from "react";
import PhoneInput from "react-phone-input-2";
import styled from "styled-components";
import "../footer/styles.css";

export const InputInner = styled.div`
  display: flex;
  flex: 1 1 0%;
  box-sizing: inherit;
`;

export default function PhoneInputForm({ attribute, value, setValue, type, onKeyDown, countryCode }) {


    return (
        <InputInner >
            <PhoneInput
                containerClass={"widget-ph-containerClass"}
                inputClass={"widget-ph-inputClass"}
                buttonClass={"widget-ph-buttonClass"}
                dropdownClass={"widget-ph-dropdownClass"}
                searchClass={"widget-ph-searchClass"}
                value={value}
                enableSearch={true}
                disableSearchIcon={true}
                country={countryCode ? countryCode.toLowerCase() : "us"}
                onChange={(e) => {
                    setValue(e);
                }}
                onKeyDown={onKeyDown}
            />
        </InputInner>

    );

}
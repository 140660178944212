import validator from 'validator';
import _ from "lodash";
import { attributeTypes } from '../../components/footer/constants';
import dayjs from 'dayjs';

export const checkMessageType = (botQuestions, questionIndex) => {
  if (!botQuestions) return ''
  const question = botQuestions[questionIndex]
  if (!question) {
    return ''
  }
  return question.attributeData && question.attributeData.inputType
}

export const getOptions = (botQuestions, questionIndex) => {
  const question = botQuestions[questionIndex]
  if (!question) {
    return []
  }
  return question.attributeData && question.attributeData.options
    ? question.attributeData.options
    : []
}
export const validate = (questions, index, inputValue) => {
  const question = questions[index];

  switch (question.attributeData.inputType) {
    case attributeTypes.TEXT:
    case attributeTypes.FILE:
    case attributeTypes.AI_WIZARD:
      if (_.trim(inputValue) === '' && (question.isMandatory || question.attributeData.isRequired)) {
        return { status: false, message: 'This Field is mandatory' };
      }
      else if (question.attributeData.validation === 'email' && !validator.isEmail(inputValue || "") && inputValue) {
        return { status: false, message: 'Please enter a valid email address' };
      }
      else if (question.attributeData.validation === 'url' && !validator.isURL(inputValue || "") && inputValue) {
        return { status: false, message: 'Please enter a valid url' };
      }
      else if (question.attributeData.validation === 'phone-number' && !validator.isMobilePhone(inputValue || "") && inputValue) {
        return { status: false, message: 'Please enter a valid phone number' };
      }
      return { status: true, message: '' };
    case attributeTypes.CURRENCY:
    case attributeTypes.NUMBER:
      if (isNaN(inputValue) && (question.isMandatory || question.attributeData.isRequired)) {
        return { status: false, message: 'This Field is mandatory' };
      }
      return { status: true, message: '' };
    case attributeTypes.CHECKBOX:
      if (!_.isBoolean(inputValue) && (question.isMandatory || question.attributeData.isRequired)) {
        return { status: false, message: 'This Field is mandatory' };
      }
      return { status: true, message: '' };
    case attributeTypes.DATE:
    case attributeTypes.TIMESTAMP:
      if (!inputValue && (question.isMandatory || question.attributeData.isRequired)) {
        return { status: false, message: 'This Field is mandatory' };
      }
      if (!dayjs(inputValue).isValid()) {
        return { status: false, message: 'Please enter a valid date' };
      }
      return { status: true, message: '' };
    case attributeTypes.RATING:
      if (isNaN(inputValue) && (question.isMandatory || question.attributeData.isRequired)) {
        return { status: false, message: 'This Field is mandatory' };
      }
      if (inputValue < 0 || inputValue > 5) {
        return { status: false, message: 'Please enter a valid rating' };
      }
      return { status: true, message: '' };
    case attributeTypes.STATUS:
    case attributeTypes.SELECT:
    case attributeTypes.MULTISELECT:
      if (_.isEmpty(inputValue) && (question.isMandatory || question.attributeData.isRequired)) {
        return { status: false, message: 'This Field is mandatory' };
      }
      return { status: true, message: '' };
    case attributeTypes.RECORD_REFERENCE:
    case attributeTypes.ACTOR_REFERENCE:
      return { status: true, message: '' };
    case attributeTypes.DOMAIN:
      if (_.trim(inputValue) === '' && (question.isMandatory || question.attributeData.isRequired)) {
        return { status: false, message: 'This Field is mandatory' };
      }
      else if (question.attributeData.validation === 'url' && !validator.isURL(inputValue || "")) {
        return { status: false, message: 'Please enter a valid url' };
      }
      return { status: true, message: '' };

    case attributeTypes.EMAIL_ADDRESS:
      console.log('inputValue', inputValue)
      if ((_.trim(inputValue) === '' || !inputValue) && (question.isMandatory || question.attributeData.isRequired)) {
        return { status: false, message: 'This Field is mandatory' };
      }
      else if (question.attributeData.validation === 'email' && inputValue && !validator.isEmail(inputValue || "")) {
        return { status: false, message: 'Please enter a valid email address' };
      }
      return { status: true, message: '' };
    case attributeTypes.PHONE_NUMBER:
      if (_.trim(inputValue) === '' && (question.isMandatory || question.attributeData.isRequired)) {
        return { status: false, message: 'This Field is mandatory' };
      }
      else if (question.attributeData.validation === 'phone-number' && !validator.isMobilePhone(inputValue || "") && inputValue) {
        return { status: false, message: 'Please enter a valid phone number' };
      }
      return { status: true, message: '' };
    default:
      break;
  }
  return { status: true, message: '' }
}

export const hexToLuma = (colour) => {
  const hex = colour.replace(/#/, '')
  const r = parseInt(hex.substr(0, 2), 16)
  const g = parseInt(hex.substr(2, 2), 16)
  const b = parseInt(hex.substr(4, 2), 16)

  const value = [0.299 * r, 0.587 * g, 0.114 * b].reduce((a, b) => a + b)

  if (value > 186) return '#000000'
  else return '#ffffff'
}

export const setOpacity = (color, opacity) => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 0.1, 0), 1) * 255)
  return color + _opacity.toString(16).toUpperCase()
}

const subscribeToEvents = (element, eventName, eventHandler) => {
    if (element.addEventListener) {
        element.addEventListener(eventName, eventHandler);
    } else if (element.attachEvent) {
        element.attachEvent(`on${eventName}`, eventHandler);
    }
};

const unSubscribedToEvent = (element, eventName, eventHandler) => {
    if (element.removeEventListener) {
        element.removeEventListener(eventName, eventHandler);
    } else if (element.detachEvent) {
        element.detachEvent(`on${eventName}`, eventHandler);
    }
};

export const ZixWAdataValidator = (data) => {
    const { brandColor, ctaText, position, brandName, phoneNumber, brandHeadline, messageText, brandImg } = data;
    if (brandColor === undefined) return false;
    else if (ctaText === undefined) return false;
    else if (position === undefined) return false;
    else if (brandName === undefined) return false;
    else if (phoneNumber === undefined) return false;
    else if (brandHeadline === undefined) return false;
    else if (messageText === undefined) return false;
    // else if (autoShow === undefined) return false;
    else if (brandImg === undefined) return false;

    if (typeof brandColor !== "string") return false;
    else if (typeof ctaText !== "string") return false;
    else if (typeof position !== "string") return false;
    else if (typeof brandName !== "string") return false;
    else if (typeof phoneNumber !== "string") return false;
    else if (typeof brandHeadline !== "string") return false;
    else if (typeof messageText !== "string") return false;
    // else if (typeof autoShow !== "boolean") return false;
    else if (typeof brandImg !== "string") return false;

    return true;
}



export { subscribeToEvents, unSubscribedToEvent };

import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Col, Form, Row } from 'react-bootstrap';
import { attributeTypes } from '../../../../components/footer/constants';
import FormInput from './text-field';
import RatingInput from './rating';
import CheckboxInput from './checkbox';
import CurrencyInput from './currency';
import PhoneInputForm from './phone';
import SelectInput from './select';
import MultiSelectInput from "./multi-select";
import StatusSelect from './status';
import { hexToLuma } from '../../../widget/helper';

const getAPIKeyName = (question) => {
    let keyName = ''
    for (const each of question.path) {
        if (keyName) keyName += '_'
        keyName += each.attributeId;
    }
    return keyName;
}
export default function FormBuilder({ questions, formData, countryCode, handleChange, customisation, welcomeScreenData, handleFormSubmit, errorData, theme }) {
    const renderInput = ({ attribute, value, setValue, onKeyDown, countryCode, question }) => {
        switch (attribute.inputType) {
            case attributeTypes.TEXT:
                return <FormInput attribute={attribute} value={value} setValue={setValue} type={"text"} onKeyDown={onKeyDown} question={question} />
            case attributeTypes.NUMBER:
                return <FormInput attribute={attribute} value={value} setValue={setValue} type={"number"} onKeyDown={onKeyDown} question={question} />
            case attributeTypes.CHECKBOX:
                return <CheckboxInput attribute={attribute} value={value} setValue={setValue} type={"number"} onKeyDown={onKeyDown} question={question} />
            case attributeTypes.CURRENCY:
                return <CurrencyInput attribute={attribute} value={value} setValue={setValue} onKeyDown={onKeyDown} question={question} />
            case attributeTypes.DATE:
                return <FormInput attribute={attribute} value={value} setValue={setValue} type={"date"} onKeyDown={onKeyDown} question={question} />
            case attributeTypes.TIMESTAMP:
                return <FormInput attribute={attribute} value={value} setValue={setValue} type={"datetime-local"} onKeyDown={onKeyDown} question={question} />
            case attributeTypes.RATING:
                return <RatingInput attribute={attribute} value={value} setValue={setValue} onKeyDown={onKeyDown} question={question} />
            case attributeTypes.STATUS:
                return <StatusSelect attribute={attribute} value={value} setValue={setValue} onKeyDown={onKeyDown} question={question} />
            case attributeTypes.SELECT:
                if (attribute.isMultiSelect) {
                    return <MultiSelectInput attribute={attribute} value={value || []} setValue={setValue} onKeyDown={onKeyDown} question={question} />
                }
                return <SelectInput attribute={attribute} value={value} setValue={setValue} onKeyDown={onKeyDown} question={question} />
            case attributeTypes.MULTISELECT:
                return <MultiSelectInput attribute={attribute} value={value || []} setValue={setValue} onKeyDown={onKeyDown} question={question} />
            case attributeTypes.DOMAIN:
                return <FormInput attribute={attribute} value={value} setValue={setValue} type={"text"} onKeyDown={onKeyDown} question={question} />
            case attributeTypes.FILE:
                return <FormInput attribute={attribute} value={value} setValue={setValue} type={"text"} onKeyDown={onKeyDown} question={question} />
            case attributeTypes.AI_WIZARD:
                return <FormInput attribute={attribute} value={value} setValue={setValue} type={"text"} onKeyDown={onKeyDown} question={question} />
            case attributeTypes.EMAIL_ADDRESS:
                return <FormInput attribute={attribute} value={value} setValue={setValue} type={"text"} onKeyDown={onKeyDown} question={question} />
            case attributeTypes.PHONE_NUMBER:
                return <PhoneInputForm attribute={attribute} value={value} setValue={setValue} onKeyDown={onKeyDown} countryCode={countryCode} />
            default:
                return <div></div>;
        }
    }
    return <FormBuilderWrapper>
        {welcomeScreenData.isEnabled && (welcomeScreenData.descrption || welcomeScreenData.mediaUrl) && <React.Fragment>
            <NFFromHeader>
                {welcomeScreenData.mediaUrl && <NFHeaderMedia src={welcomeScreenData.mediaUrl} />}
                {welcomeScreenData.descrption && <div className="content-wrapper" dangerouslySetInnerHTML={{ "__html": welcomeScreenData.descrption }}></div>}
            </NFFromHeader>
            <NFContainerHr />
        </React.Fragment>}

        {questions.map((question, index) => {
            return <Col sm={12} lg={12} key={index}>
                <Form.Group id={`question-${index}`} style={{ marginBottom: "30px", "--answer": customisation.answer }}>
                    <QuestionLabel>{question.question} {question.isMandatory && <span className='text-danger'>*</span>}</QuestionLabel>
                    {renderInput({
                        question: question.question,
                        attribute: question.attributeData,
                        value: formData[question.attributeData._id],
                        setValue: (value) => handleChange(getAPIKeyName(question), value),
                        onKeyDown: () => { },
                        countryCode: countryCode
                    })}
                    {errorData[getAPIKeyName(question)] && (
                        <ErrorMessageWrapper>
                            <ErrorMessage>{errorData[getAPIKeyName(question)]}</ErrorMessage>
                        </ErrorMessageWrapper>
                    )}
                </Form.Group>
            </Col>
        })}
        {questions.length > 0 && !_.isEmpty(theme) && <Col sm={12} lg={12} className='d-flex flex-center w-100'>
            <SubmitButton onClick={handleFormSubmit}>Submit</SubmitButton>
        </Col>}
    </FormBuilderWrapper>
};

const FormBuilderWrapper = styled(Row)`
    width: 100%;
    background: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    font-family: 'Lato', sans-serif;
    // font-family: 'Open Sans', sans-serif;
    // font-family: 'Roboto', sans-serif;
   
   
    @media (max-width: 768px) {
        padding: 20px;
        border-radius: 0px;
        box-shadow: none;

    }

    & > * {
        padding: 0px;
    }
`;

const QuestionLabel = styled.div`
    // color: #1a202c;
    font-size: 1rem;
    color: #232529;
    margin-bottom: 0.5rem;
`;

export const NFFromHeader = styled.div`
  margin-bottom: 20px;

  .content-header{
    color : #232529;
    font-family: Inter;
    font-style: 12px;
    font-weight: 500;
    line-height: 100%; /* 16px */
    letter-spacing: -0.08px;
    font-size: 20px !important;
    padding-bottom: 12px;

  }

  .content-wrapper{
    color : #232529;
    font-family: Inter;
    font-style: 12px;
    font-weight: 400;
    line-height:  25px ;
    letter-spacing: -0.08px;
    font-size: 16px !important;

    p{
        font-size: 14px !important;
    }
    h1 {
        font-size: 24px !important;
    }
    h2 {
        font-size: 22px !important;
    }
    h3 {
        font-size: 20px !important;
    }
    h4 {
        font-size: 18px !important;
    }
    h5 {
        font-size: 16px !important;
    }
    h6 {
        font-size: 14px !important;
    }
    h7 {
        font-size: 12px !important;
    }
  }

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {

  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {

  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {

  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {

  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    
  }

`;
export const NFHeaderMedia = styled.img`
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 20px;


  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    height: 100px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    height: 150px;
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    height: 200px;
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    height: 200px;
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    height: 200px;
  }
`;

export const NFContainerHr = styled.hr`
width: 100%;
  // border-bottom: 2px solid ${(props) => props.theme.question};
`;

export const ErrorMessageWrapper = styled.div`
//   padding: 12px;
`

export const ErrorMessage = styled.div`
//   padding: 0px 12px 0px 12px;
//   line-height: 2rem;
  font-size: 13px;
  background: #fff;
  color: #d63031;
  margin-top: 4px;
`
export const SubmitButton = styled.button`
  color: ${(props) => hexToLuma(props.theme.button)};
  outline: none;
  border: none;
  display: inline-flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: ${(props) => props.theme.button};;

  /* Button */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.09);

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
      width: 100%;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    width: 100%;
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    width: fit-content;
    }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    width: fit-content;
    }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    width: fit-content;
    }

`
export const attributeTypes = {
    TEXT: "text",
    NUMBER: "number",
    CHECKBOX: "checkbox",
    CURRENCY: "currency",
    DATE: "date",
    TIMESTAMP: "timestamp",
    RATING: "rating",
    STATUS: "status",
    SELECT: "select",
    MULTISELECT: "multiselect",
    RECORD_REFERENCE: "record-reference",
    ACTOR_REFERENCE: "actor-reference",
    DOMAIN: "domain",
    FILE: "file",
    AI_WIZARD: "ai-wizard",
    EMAIL_ADDRESS: "email-address",
    PHONE_NUMBER: "phone-number"
}
const subscribeToEvents = (element, eventName, eventHandler) => {
    if (element.addEventListener) {
        element.addEventListener(eventName, eventHandler);
    } else if (element.attachEvent) {
        element.attachEvent(`on${eventName}`, eventHandler);
    }
};

const unSubscribedToEvent = (element, eventName, eventHandler) => {
    if (element.removeEventListener) {
        element.removeEventListener(eventName, eventHandler);
    } else if (element.detachEvent) {
        element.detachEvent(`on${eventName}`, eventHandler);
    }
};
export { subscribeToEvents, unSubscribedToEvent };

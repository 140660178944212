import * as classNames from "classnames";
import * as React from "react";
import * as RTG from "react-transition-group";

function Notifier(props) {
    const classes = classNames("sales-simplify-notification");

    const renderNotificationBody = () => {
        return (
            <div className="notification-wrapper">
                <div className="notification-body" onClick={props.onContentClick}>{props.content}</div>
            </div>
        );
    }

    console.log(props.isVisible);
    return (
        <RTG.CSSTransition
            in={props.isVisible}
            appear={true}
            timeout={300}
            classNames="scale-in"
            unmountOnExit={true}
        >
            <div className={classes}>
                <div
                    className="flex-notification-container"
                >
                    {renderNotificationBody()}
                </div>
                <div
                    className="close-notification"
                    title="Close notification"
                    onClick={() => {
                        props.onClose();
                    }}
                >
                    <i className="bi bi-x"></i>
                </div>
            </div>
        </RTG.CSSTransition>
    );
}

export default Notifier;

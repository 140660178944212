import React from "react";
import styled from "styled-components";

const NotAvailableContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  @media (min-width:10px )  { 
    flex-direction: column;
  }
  @media (min-width:801px)  { 
    flex-direction: row;
  }
`;

const NotAvailableSection1 = styled.div`
  width: 50%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #25dbc3;
  @media (min-width:10px )  { 
    width: 100%;
    min-height: 100%;
  }
  @media (min-width:801px)  { 
    width: 50%;
  }
`;

const NotAvailableSection2 = styled.div`
  width: 50%;
  height: 100%;
  background-color: #25dbc3;
  @media (min-width:10px )  { 
    width: 100%;
    display: none;
  }
  @media (min-width:801px)  { 
    width: 50%;
    display:block;
  }
`;

const NotAvailableBranding = styled.div`

`;
const NotAvailableBrandingImgae = styled.img`
  width: 100px;
`;

const NotAvailableBannerImage = styled.img`
  width: 100%;
  height: inherit;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 80px);
  flex-direction: column;
  padding: 40px;
  text-align: center;
  box-sizing: border-box;
  @media (min-width:10px )  { 
    padding: 0px;
  }
  @media (min-width:801px)  { 
    padding: 40px;
  }
`;
const HeadText = styled.div`
  font-size: 30px;
  margin-bottom: 20px;
`;
const SubText = styled.div`
  font-size: 16px;
`;

export const WsButton = styled.a`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px;
  padding: 0px 12px;
  line-height: 20px;
  min-height: 44px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px 0px;
  transition: all 0.2s ease 0s;
  text-align: center;
  cursor: pointer;
  background: #017ff6;
  border: 1px solid #017ff6;
  color: white;
  outline: 0px;
  margin-right:10px;
  text-decoration:none;

  &:hover{
    opacity: 0.75;
    background: #017ff6;
    border: 1px solid #017ff6;
    color: white;
  }
`;

export const NotAvailableButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const TrySSTextWraper = styled.div`
  font-size: 30px;
  margin-bottom: 20px;
  color:#002d45;
`;

export default function NotAvailable(props) {
  return <NotAvailableContainer>
    <NotAvailableSection1>
      <NotAvailableBranding>
        <NotAvailableBrandingImgae alt="branding" src="https://assets-global.website-files.com/6511240890b712e01083f24b/651127d3a440e1caefc70052_3.svg" />
      </NotAvailableBranding>
      <TextWrapper>
        <HeadText>This link is no longer active.</HeadText>
        <SubText className="ss-ld-na-subtext">Please check with the person who shared it with you to see there are new link available.</SubText>
        <br /><br />
        <br /><br />
        <br /><br />
        <div>
          <TrySSTextWraper>Try Zixflow Free</TrySSTextWraper>
          <NotAvailableButtonWrapper>
            <WsButton target='_blank' rel="noopener noreferrer" href="https://zixflow.com">Go To Website</WsButton>
            <WsButton target='_blank' rel="noopener noreferrer" href="https://app.zixflow.com/auth/registration"> Sign Up Free</WsButton>
          </NotAvailableButtonWrapper>
        </div>
      </TextWrapper>
    </NotAvailableSection1>
    <NotAvailableSection2>
      <NotAvailableBannerImage alt="page" src="https://res.cloudinary.com/salessimplifyimg/image/upload/v1645687170/LeadBot/5174540_cpnfr2.jpg" />
    </NotAvailableSection2>
  </NotAvailableContainer>
}

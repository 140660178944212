import React, { useCallback, useEffect, useState } from "react";
import Launcher from "./components/Launcher";
import * as RTG from "react-transition-group";
import Widget from "../widget/index";
import _ from "lodash";
import { getLeadbotDetails } from "../widget/api.widget";
import { setOpacity } from "../widget/helper";
import { subscribeToEvents, unSubscribedToEvent } from "./util";

const sendMessage = (params) => {
    window.parent.postMessage(
        {
            fromSalesSimplify: true,
            source: "fromMessenger",
            ...params,
        },
        '*'
    );
}

export default function Messanger(props) {
    const [isMessengerVisible, setMessengerVisible] = useState(false);
    const [showNotifier, setShowNotifier] = useState(true);
    const [introBubble, setIntroBubble] = useState("");
    const [leadBotId, setLeadBotId] = useState("");
    const [closeButtonStyles, setCloseButtonStyles] = useState({
        color: "white",
        backkground: "white",
        hoverBackhground: ""
    })


    const EventHandler = useCallback((event) => {
        if (event.data.fromPublisher) {
            if (event.data.action === "showMessenger") {
                if (event.data.setting.leadbot) {
                    setLeadBotId(event.data.setting.leadbot)
                    sendMessage({
                        isVisible: true,
                        message: "notifier",
                        isSmallContainer: true
                    })
                }
            }
        }
    }, [])
    useEffect(() => {
        bindWindowEvent(window, "message", EventHandler)

        return () => {
            unSubscribedToEvent(window, "message", EventHandler)
        };
    }, [EventHandler])

    useEffect(() => {
        async function fetchData() {
            try {
                const leadBotData = await getLeadbotDetails(leadBotId);
                if (!_.isEmpty(leadBotData.data)) {
                    setIntroBubble(leadBotData.data.customization.introBubble)
                    setCloseButtonStyles({
                        color: leadBotData.data.customization.button,
                        backkground: setOpacity(leadBotData.data.customization.button, 0.1),
                        hoverBackhground: setOpacity(leadBotData.data.customization.button, 0.3)
                    })
                }
                else {
                    setCloseButtonStyles({
                        color: "#017ff6",
                        backkground: setOpacity("#017ff6", 0.1),
                        hoverBackhground: setOpacity("#017ff6", 0.3)
                    })
                }
            }
            catch (err) {
                setCloseButtonStyles({
                    color: "#017ff6",
                    backkground: setOpacity("#017ff6", 0.1),
                    hoverBackhground: setOpacity("#017ff6", 0.3)
                })
                console.log("err", err)
            }
        }
        if (leadBotId) fetchData();
    }, [leadBotId]);

    const bindWindowEvent = (element, eventName, eventHandler) => {
        subscribeToEvents(element, eventName, eventHandler);
    }


    const handlerLauncherClick = (currentState) => {
        setMessengerVisible(!currentState);
        setShowNotifier(false);

        sendMessage({
            isVisible: !currentState,
            message: "messenger",
            isSmallContainer: currentState
        });
    }
    const handlerNotifierOff = () => {
        setShowNotifier(false);
        sendMessage({
            isVisible: false,
            message: "notifier",
            isSmallContainer: true
        });
    }
    const onContentClick = () => {
        setMessengerVisible(!isMessengerVisible);
        setShowNotifier(false);

        sendMessage({
            isVisible: !isMessengerVisible,
            message: "messenger",
            isSmallContainer: isMessengerVisible
        });
    }


    const renderLauncher = () => {
        return <Launcher
            isMessengerVisible={isMessengerVisible}
            onClick={handlerLauncherClick}
            onContentClick={onContentClick}
            uiOptions={{
                color: closeButtonStyles.color,
                logo: ""
            }}
            showNotifier={showNotifier}
            introBubble={introBubble}
            setShowNotifier={handlerNotifierOff}

        />
    }

    return <React.StrictMode>
        <div className="sales-simplify-widget">
            <RTG.CSSTransition
                in={isMessengerVisible}
                timeout={300}
                classNames="scale-in"
                unmountOnExit={true}
            >
                <div className="sales-simplify-messenger">
                    <div className="sales-simplify-conversation-detail">
                        <Widget leadBotId={leadBotId} />
                        <div className="sales-simolify-close-icon-wrapper"
                            style={{ "--btn-color": closeButtonStyles.color, "--btn-background": closeButtonStyles.backkground, "--btn-hover-bg": closeButtonStyles.hoverBackhground }}
                            onClick={() => { handlerLauncherClick(isMessengerVisible) }}><i className="bi bi-x"></i></div>
                    </div>
                </div>
            </RTG.CSSTransition>
            {renderLauncher()}
        </div>
    </React.StrictMode>
};
import React, { useState } from "react";
import styled from "styled-components";
import "react-phone-input-2/lib/style.css";
import { hexToLuma } from "../../containers/widget/helper";
import "./styles.css";
import CustomInput from "../custom-input";


export default function Footer(props) {
  const [inputData, setInputData] = useState("");

  const onInputClick = (event) => {
    // event.stopPropagation();
    // event.preventDefault();
    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      if (typeof inputData === "string" && inputData.trim().length > 0) {
        props.onInputSubmit(inputData.trim());
      }
      else {
        props.onInputSubmit(inputData);
      }
      setInputData("");
    }
  };

  const onInputSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof inputData === "string" && inputData.trim().length > 0) {
      props.onInputSubmit(inputData.trim());
    }
    else {
      props.onInputSubmit(inputData);
    }
    setInputData("");
  }
  return <ConversationFooter>
    <UserInputContainer>
      <UserInputWrapper>
        <InputBox>
          {props.question ?
            <InputWrapper style={{ "--answer": props.customisation.answer }}>
              <CustomInput
                attribute={props.question.attributeData}
                value={inputData}
                setValue={(value) => setInputData(value)}
                onKeyDown={onInputClick}
                countryCode={props.countryCode ? props.countryCode.toLowerCase() : "us"}
              />
              <SendButton onClick={(e) => onInputSubmit(e)}>
                <i className="bi bi-send-fill" ></i>
              </SendButton>
            </InputWrapper> :
            <></>}
        </InputBox>
      </UserInputWrapper>
    </UserInputContainer>
  </ConversationFooter>
}

const ConversationFooter = styled.div`
  box-sizing: inherit;
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
  position: relative;
  overflow: inherit;
  transition: max-height 500ms cubic-bezier(0.19, 1, 0.22, 1) 0ms, bottom 400ms cubic-bezier(0.19, 1, 0.22, 1) 200ms, opacity 400ms ease-out 180ms;
  bottom: 0px;
  opacity: 1;
  max-height:  336px;//74px;//var(--max-height);
`;

const UserInputContainer = styled.div`
  width: 100%;
  box-sizing: inherit;
`;

const UserInputWrapper = styled.div`
  padding: 0px 16px 16px;
  box-sizing: inherit;
`;
const InputBox = styled.div`
  box-sizing: inherit;
  opacity: 1;
  transition: opacity 0.3s ease 0s;
`;

const InputWrapper = styled.form`
  display: flex;
  box-sizing: inherit;
`

const SendButton = styled.button`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 50px;
  border-radius: 4px;
  padding: 0px;
  transition: opacity 0.2s ease 0s;
  width: 50px;
  margin-left: 8px;
  background: ${props => props.theme.button};
  border: 1px solid ${props => props.theme.button};
  color: ${props => hexToLuma(props.theme.button)};
`;

import React from "react";
import styled from "styled-components";

export const InputInner = styled.div`
  display: flex;
  flex: 1 1 0%;
  box-sizing: inherit;
  width: 100%;
`;
export const Input = styled.input`
  background: transparent;
  color:  ${props => props.theme.answer};
  font-size: 16px;
  font-family: inherit;
  height: 50px;
  width: 100%;
  padding: 10px 16px;
  border: 1px solid ${props => props.theme.answer};
  border-radius: 7px;
  line-height: 20px;
  outline: none;
  transition: all 0.3s ease 0s;
  appearance: none;
`;
export default function FormInput({ attribute, value, setValue, type, onKeyDown }) {
  return (
    <InputInner>
      <Input
        type={type}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={onKeyDown}
        placeholder={`Enter ${attribute.name}`}
      />
    </InputInner>
  );

}
import React from "react";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import checkSVG from "../../../../images/svgs/check.svg";

export const InputInner = styled.div`
  display: flex;
  flex: 1 1 0%;
  box-sizing: inherit;
`;

export default function SelectInput({ attribute, value, setValue, type, onKeyDown }) {
    const [show, setShow] = React.useState(false);
    const [checked, setChecked] = React.useState([]);

    const [options, setOptions] = React.useState([]);

    React.useEffect(() => {
        if (attribute.options) {
            setOptions(attribute.options);
        }
    }, [attribute]);

    const onValueChange = (option) => {
        if (checked.includes(option._id)) {
            setChecked(checked.filter((each) => each !== option._id));
            setValue(value.filter((each) => each._id !== option._id));
        }
        else {
            setChecked([...checked, option._id]);
            setValue([...value, option]);
        }
    }

    return <InputInner onKeyDown={onKeyDown}>
        <DropdownRoot show={show} onToggle={() => setShow(!show)} className="dropdown-inline hide-dropdonw-icon w-100">
            <DropdownToggle variant="transparent">
                <div className="d-flex flex-wrap gap-1">
                    {value?.length > 0 ? value.map((each) => <OptionDisplay className="" optioncolor={each.color}>
                        {each.name}
                    </OptionDisplay>) : <div style={{ color: "gray" }}>{"Select " + attribute.name}</div>}
                </div>
                {/* <SelectDisplay row={row} column={column} attribute={attribute} /> */}
            </DropdownToggle>
            <DropdownMenu className="user-select-none">
                <DropdownContent>
                    {options.map((option) => (
                        <OptionWrapper key={option._id} onClick={() => onValueChange(option)}>
                            <div className="w-100-40px text-truncate ">
                                <OptionDisplay className="" optioncolor={option.color}>
                                    {option.name}
                                </OptionDisplay>
                            </div>
                            {checked.includes(option._id) ? <img src={checkSVG} alt="check" /> : <span></span>}
                        </OptionWrapper>
                    ))}
                </DropdownContent>
            </DropdownMenu>
        </DropdownRoot>
    </InputInner>
}

const DropdownRoot = styled(Dropdown)`
  width: 100%;
  height: 100%;
//   padding: 10px 15px;
  border: 1px solid #e2e8f0;
`;

const DropdownToggle = styled(Dropdown.Toggle)`
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex-wrap: wrap;
    // gap: 10px;
    border-radius: 3px;

    // padding: 10px 15px;
    // border: 1px solid #e2e8f0;
    // line-height: 1rem;
    // outline: none;
    // transition: all 0.3s ease 0s;
    // box-shadow: 0px 0px 1px 1px #e2e8f094;



    &::after {
        display: inline-block;
        align-selft: center !important;
        // margin-left: 0.255em;
        // vertical-align: 1.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }

`;

const DropdownMenu = styled(Dropdown.Menu)`
  // transform: translate(0px, 0px) !important;
  border: 1px solid #D5D4D6;
  margin: 0px;
  width: 300px;
`;
const DropdownContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 1px 7px;
  line-height: 36px;
`;

const OptionWrapper = styled.div`
  width: 100%;
  border-radius: 6px;
  padding: 0px 5px;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    background: #F1F1F1;
  }
`;
const OptionDisplay = styled.div`
  display: unset;
  border-radius: 6px;
    color: #232529;
  font-size: 12px;
  padding: 3px 8px;
  background: ${(props) => props.optioncolor};
`;
import React from "react";
import styled from "styled-components";
import { setOpacity } from "../../containers/widget/helper";

export const InputInner = styled.div`
  display: flex;
  flex: 1 1 0%;
  box-sizing: inherit;
`;

export const ListRootColumn = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 100%;
`;
export const ListButton = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: left;
  margin: 0px 0px 8px;
  padding: 12px 15px;
  line-height: 20px;
  // min-height: 44px;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  color: ${props => props.theme.answer};;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  width: fit-content;
  max-width: 340px;
  border: none;
  box-shadow:${props => props.theme.answer} 0px 0px 0px 1px inset;
    background: transparent;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  &:hover{
    background: ${props => setOpacity(props.theme.answer, 0.15)};
  }

    &.active{
        background: ${props => setOpacity(props.theme.answer, 0.15)};
    }
`;
export default function CheckboxInput({ attribute, value, setValue, type, onKeyDown }) {
  const _onKeyDown = e => {
    if (e.keyCode === 13) {
      onKeyDown(e);
    }
    else {
      e.stopPropagation();
      e.preventDefault();
    }
  }
  return (
    <InputInner>
      <ListRootColumn onKeyDown={_onKeyDown}>
        {["Yes", "No"].map((each, index) => {
          return <ListButton className={(value && each === "Yes") || (!value && each === "No") ? "active" : ""} key={index} onClick={() => setValue(each === "Yes" ? true : false)}>{each}</ListButton>
        })}
      </ListRootColumn>
    </InputInner>
  );

}
import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import _ from "lodash";
import "./styles.css";
import styled from 'styled-components';

const OptionWrapper = styled.div`
    width: 100%;
    border-radius: 6px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3px;
    &:hover {
        cursor: pointer;
    }
`;
const OptionDisplay = styled.div`
    border-radius: 4px;
    padding: 4px 12px;
    font-size: 0.85rem;
    display: flex;
    align-items: center;
    color: black;
    background:${props => props.optioncolor};
`;
const StatusOptionDisplay = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 10px;
    padding: 2px 6px;

    &:hover {
        cursor: pointer;
        background:${props => props.optioncolor}33;
    }
`;

const DropdownRoot = styled(Dropdown)`
    width: 100%;
    height: 100%;
`;
const DropdownToggle = styled(Dropdown.Toggle)`
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #232529;
    font-size: 1rem;
    font-family: inherit;
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #e2e8f0;
    border-radius: 3px;
    line-height: 1rem;
    outline: none;
    transition: all 0.3s ease 0s;
    box-shadow: 0px 0px 1px 1px #e2e8f094;
  
    &:focus {
      border: 1px solid ${props => props.theme.answer};
      box-shadow: 0px 0px 1px 1px ${props => props.theme.answer};;
    }

`;

const DropdownMenu = styled(Dropdown.Menu)`
    border-radius: none;
    // box-shadow: 1px 0px 9px -2px ${props => props.theme.answer}11;
    margin: 0px;
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
`
const DropdownContent = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 1px 7px;
    gap: 5px;
    // line-height: 36px;
`;


const StatusDot = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${props => props.optioncolor};
`;

export default function SelectDropdown({ list, value, setValue, label, type }) {
    const [show, setShow] = useState(false)

    return <DropdownRoot show={show} onToggle={() => setShow(!show)} className="dropdown-inline hide-dropdonw-icon w-100">
        <DropdownToggle variant="transparent">
            {!_.isEmpty(value) ?

                <OptionWrapper key={value._id}>
                    <div className="w-100-40px text-truncate">
                        {
                            type === "select" ? <OptionDisplay type={type} className="" optioncolor={value.color} >
                                {value.name}
                            </OptionDisplay>
                                : <StatusOptionDisplay type={type} className="" >
                                    <StatusDot optioncolor={value.color}></StatusDot>
                                    {value.name}
                                </StatusOptionDisplay>
                        }

                    </div>
                </OptionWrapper> : "Select " + label}
        </DropdownToggle>
        <DropdownMenu className="user-select-none">
            <DropdownContent>
                {list.map((option, index) => (
                    <OptionWrapper key={index} onClick={() => { setValue(option); setShow(false) }}>
                        <div className="w-100-40px text-truncate ">
                            {
                                type === "select" ? <OptionDisplay type={type} className="" optioncolor={option.color} >
                                    {option.name}
                                </OptionDisplay>
                                    : <StatusOptionDisplay type={type} className="" optioncolor={option.color} >
                                        <StatusDot optioncolor={option.color}></StatusDot>
                                        {option.name}
                                    </StatusOptionDisplay>
                            }


                        </div>
                    </OptionWrapper>
                ))}
            </DropdownContent>
        </DropdownMenu>
    </DropdownRoot>

}
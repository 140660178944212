import React, { useState } from "react";
import "./zx-wa-styles.scss";
import { hexToLuma } from "../../widget/helper";

// const waImage = "https://res.cloudinary.com/salessimplifyimg/image/upload/v1714738294/whatsapp2_h67krk.png";

const Bot = ({ options, setMessengerVisible }) => {

    const [isHovered, setIsHovered] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    const defaultStyle = { backgroundColor: options.brandColor };
    const hoverStyle = { boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px', backgroundColor: options.brandColor };


    // const options = {
    //     brandColor: "#00E785",
    //     ctaText: "Chat on WhatsApp",
    //     position: "left",
    //     brandName: "Zixflow",
    //     phoneNumber: "919308679681",
    //     brandHeadline: "Hi there! How can I help you?",
    //     messageText: "{{page_link}}Hello, %0A I have a question a{{page_title}}bout {{page_link}}",
    //     autoShow: true,
    //     brandImg: "https://cdn.prod.website-files.com/6511240890b712e01083f24b/651127d3a440e1caefc70052_3.svg"
    // };

    const whatsappLink = `https://api.whatsapp.com/send?phone=${options.phoneNumber}&text=${encodeURIComponent(options.messageText)}`;
    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(whatsappLink)}&size=90x90`;

    return (
        <div className="zxwa-widget-body">
            <div className="zxwa-widget-body-inner">
                <div className="d-flex flex-row align-items-center gap-3 h-100 w-100">
                    <img src={options.brandImg} alt="brand" className="zxwa-widget-brand-image" />
                    <div className="py-20px">
                        <div className="d-flex flex-column p-0">
                            <p className="text-white mb-0" style={{ fontSize: '15px', lineHeight: 'normal', fontWeight: "600", letterSpacing: '1px' }}>{options.brandName}</p>
                            <p className="text-white mb-0 mt-1" style={{ fontSize: '13px', lineHeight: 'normal', fontWeight: "600" }}>Online</p>
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex flex-row align-items-center justify-content-center h-100"
                    onClick={() => { setMessengerVisible(false) }}
                    onMouseEnter={() => setIsClosing(true)}
                    onMouseLeave={() => setIsClosing(false)}
                >
                    <i className={`d-flex bi bi-x font-weight-bold cursor-pointer text-white`} style={{ fontSize: '30px', cursor: 'pointer', backgroundColor: isClosing ? '#0ea38f' : 'inherit', borderRadius: isClosing ? '50%' : 'none' }} ></i>
                </div>
            </div>
            <div className="zxwa-text-display">
                <div className="zxwa-chat-bubble-wrapper">
                    <div className="d-flex flex-column">
                        {/* <p className="mb-0 fs-6">{options.brandName}</p> */}
                        <p className="mb-0 text-break">{options.brandHeadline}</p>
                    </div>
                </div>
                <div className="d-flex flex-column w-100  justify-content-center align-items-center px-60px">
                    <div className="zxwa-chat-bubble-wrapper2 mx-40px bg-danger">
                        <div className="d-flex flex-column">
                            <img src={qrCodeUrl} alt="QR Code" />
                        </div>
                        <div className="zxwa-start-chat-section">
                            <a
                                rel="noopener noreferrer"
                                role="button"
                                target="_blank"
                                href={`https://api.whatsapp.com/send?phone=${options.phoneNumber}&text=${encodeURIComponent(options.messageText)}`}
                                title="WhatsApp"
                            >
                                <button className="start-chat-button"
                                    // style={{ backgroundColor: options.brandColor }}
                                    style={isHovered ? hoverStyle : defaultStyle}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                >
                                    <div className="start-chat-button-inner">
                                        <span style={{ color: hexToLuma("#22C065") }} className="zx-chat-box-content-send-btn-text">{options.ctaText}</span>
                                    </div>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center p-16px h-100 my-2">
                <p className="zxwa-powered-by-label" style={{ fontSize: '14px', lineHeight: '22px' }}>
                    <span>
                        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.84653 0.0360377C6.13974 0.138097 6.33912 0.438354 6.33912 0.777828V4.66669L9.15651 4.66669C9.41915 4.66669 9.65997 4.82804 9.78125 5.08527C9.90254 5.34251 9.88415 5.65289 9.73354 5.89048L4.80311 13.6682C4.62681 13.9463 4.30753 14.066 4.01433 13.964C3.72113 13.8619 3.52174 13.5616 3.52174 13.2222L3.52174 9.33331H0.704349C0.441715 9.33331 0.200895 9.17196 0.0796083 8.91473C-0.0416787 8.65749 -0.0232851 8.34711 0.127325 8.10952L5.05775 0.331805C5.23405 0.0536972 5.55333 -0.0660216 5.84653 0.0360377Z" fill="#FFA800"></path></svg>
                        <span>
                            &nbsp;Powered by <a rel="noopener noreferrer" href="https://zixflow.com" target="_blank" className="zxwa-zixflow-label text-decoration-none">Zixflow</a>
                        </span>
                    </span>
                </p>
            </div>
        </div >
    );
};

export default Bot;
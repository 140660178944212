import React from "react";
import styled from "styled-components";

export const InputInner = styled.div`
  display: flex;
  flex: 1 1 0%;
  box-sizing: inherit;
`;
export const Input = styled.input`
  background: transparent;
  color:  ${props => props.theme.answer};
  font-size: 16px;
  font-family: inherit;
  display: flex;
  height: 50px;
  width: 100%;
  padding: 10px 16px;
  border: 1px solid ${props => props.theme.answer};
  border-radius: 0px 7px 7px 0px;
  line-height: 20px;
  outline: none;
  transition: all 0.3s ease 0s;
  appearance: none;
`;

const CurrenyHolder = styled.div`
    border: 1px solid ${props => props.theme.answer};
    color:  ${props => props.theme.answer};
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    border-radius: 7px 0px 0px 7px;
    cursor: not-allowed;
    border-right: none;
    // background: ${props => props.theme.answer}22;
`;

const currencyMapping = {
    "EUR": { name: "Euro", symbol: "€", code: "EUR", narrowSymbol: "€" },
    "GBP": { name: "British Pound Sterling", symbol: "£", code: "GBP", narrowSymbol: "£" },
    "USD": { name: "United States Dollar", symbol: "$", code: "USD", narrowSymbol: "$" },
    "CAD": { name: "Canadian Dollar", symbol: "CA$", code: "CAD", narrowSymbol: "$" },
    "AUD": { name: "Australian Dollar", symbol: "A$", code: "AUD", narrowSymbol: "$" },
    "INR": { name: "Indian Rupee", symbol: "₹", code: "INR", narrowSymbol: "₹" },
    "JPY": { name: "Japanese Yen", symbol: "¥", code: "JPY", narrowSymbol: "¥" },
    "CNY": { name: "Chinese Yuan", symbol: "¥", code: "CNY", narrowSymbol: "¥" },
    "CHF": { name: "Swiss Franc", symbol: "CHF", code: "CHF", narrowSymbol: "CHF" },
    "SGD": { name: "Singapore Dollar", symbol: "S$", code: "SGD", narrowSymbol: "$" },
    "HKD": { name: "Hong Kong Dollar", symbol: "HK$", code: "HKD", narrowSymbol: "$" },
    "NZD": { name: "New Zealand Dollar", symbol: "NZ$", code: "NZD", narrowSymbol: "$" },
    "SEK": { name: "Swedish Krona", symbol: "kr", code: "SEK", narrowSymbol: "kr" },
    "NOK": { name: "Norwegian Krone", symbol: "kr", code: "NOK", narrowSymbol: "kr" },
    "DKK": { name: "Danish Krone", symbol: "kr", code: "DKK", narrowSymbol: "kr" },
    "RUB": { name: "Russian Ruble", symbol: "₽", code: "RUB", narrowSymbol: "₽" },
    "ZAR": { name: "South African Rand", symbol: "R", code: "ZAR", narrowSymbol: "R" },
    "BRL": { name: "Brazilian Real", symbol: "R$", code: "BRL", narrowSymbol: "R$" },
    "MXN": { name: "Mexican Peso", symbol: "MX$", code: "MXN", narrowSymbol: "$" },
    "ARS": { name: "Argentine Peso", symbol: "ARS", code: "ARS", narrowSymbol: "$" },
    "KRW": { name: "South Korean Won", symbol: "₩", code: "KRW", narrowSymbol: "₩" },
    "TRY": { name: "Turkish Lira", symbol: "₺", code: "TRY", narrowSymbol: "₺" },
    "THB": { name: "Thai Baht", symbol: "฿", code: "THB", narrowSymbol: "฿" },
    "PLN": { name: "Polish Złoty", symbol: "zł", code: "PLN", narrowSymbol: "zł" },
    "HUF": { name: "Hungarian Forint", symbol: "Ft", code: "HUF", narrowSymbol: "Ft" },
    "CZK": { name: "Czech Koruna", symbol: "Kč", code: "CZK", narrowSymbol: "Kč" },
    "ILS": { name: "Israeli New Shekel", symbol: "₪", code: "ILS", narrowSymbol: "₪" },
};

export default function CurrencyInput({ attribute, value, setValue, type, onKeyDown }) {
    const [currency, setCurrency] = React.useState("");

    React.useEffect(() => {
        const _currency = currencyMapping[attribute.config?.currencyCode]?.[attribute.config?.currencyDisplayType] || "USD";
        setCurrency(_currency);
    }, [attribute]);

    return (
        <InputInner>
            <CurrenyHolder>
                {currency}
            </CurrenyHolder>
            <Input

                type={"number"}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={onKeyDown}
                placeholder={`Enter ${attribute.name}`}
            />
        </InputInner>
    );

}
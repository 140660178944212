import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getGeoLocation, getLeadbotDetails, saveResponse } from '../widget/api.widget'
import { validate } from '../widget/helper'
import { attributeTypes } from '../../components/footer/constants';
import styled, { ThemeProvider } from 'styled-components'
import FormDemo from './components/form'
import Loading from '../../components/loading/loading'
import NotAvailable from '../../components/not-available/not-available.landing-page'
import ThankYouScreen from '../thankyou-screen/thankyou-screen'
import { PoweredByWrapper } from '../normal-form/styled'
import { PoweredByA } from '../thankyou-screen/styled'

const NormalFormContainer = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    // background: #e2e8f0;
    overflow-y: auto; 
    padding: 30px;
    background: ${props => props.theme.background};
    background-image: ${props => props.theme.backgroundImage};

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
      padding: 0px;
      background-image: none;
      background: white;
    }
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        padding: 0px;
        background-image: none;
        background: white;
    }
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        padding: 20px;
        background: ${props => props.theme.background};
        background-image: ${props => props.theme.backgroundImage};
    }
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        padding: 30px;
        background: ${props => props.theme.background};
        background-image: ${props => props.theme.backgroundImage};
    }
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        padding: 30px;
        background: ${props => props.theme.background};
        background-image: ${props => props.theme.backgroundImage};
    }
`;
const NFViewContainer = styled.div`
    max-width: 780px;
    margin: 0 auto;
    // height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        margin-bottom: 20px;
    }
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        margin-bottom: 20px;
    }
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        margin-bottom: 0px;
    }
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        margin-bottom: 0px;
    }
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        margin-bottom: 0px;
    }
`;

export default function NormalFormV2(props) {
    const [formData, setFormData] = useState({})
    const [showWelcomeScreen, setShowWelcomeScreen] = useState(false)
    const [showChatHistoryScreen, setShowChatHistoryScreen] = useState(false)
    const [showThankYouScreen, setShowThankYouScreen] = useState(false)

    const [welcomeScreenData, setWelcomeScreenData] = useState({})
    const [questions, setQuestions] = useState([])
    const [thankYouScreenData, setThankYouScreenData] = useState({})
    const [customisation, setCustomisation] = useState({})
    const [showBranding, setShowBranding] = useState(true)

    const [isActive, setIsActive] = useState(false)
    const [loading, setLoading] = useState(true)
    const [theme, setTheme] = useState({})
    const [errorData, setErrorData] = useState({})
    const [countryCode, setCountryCode] = useState('us')

    const params = useParams()
    const leadBotId = params.id || props.leadBotId

    useEffect(() => {
        async function fetchData() {
            try {
                const leadBotData = await getLeadbotDetails(leadBotId)

                if (!_.isEmpty(leadBotData.data)) {
                    setIsActive(leadBotData.data.isEnabled)
                    if (leadBotData.data.name) {
                        document.title = leadBotData.data.name
                    }

                    const welcomeScreen = leadBotData.data.welcomeScreen
                    setWelcomeScreenData(welcomeScreen)
                    setThankYouScreenData(leadBotData.data.thankYouScreen)
                    setCustomisation(leadBotData.data.customization)
                    setQuestions(leadBotData.data.questionsScreen)
                    setTheme({
                        question: leadBotData.data.customization.question,
                        answer: leadBotData.data.customization.answer,
                        button: leadBotData.data.customization.button,
                        background: leadBotData.data.customization.background,
                        backgroundImage: leadBotData.data.customization.backgroundImage,
                    });

                    if (leadBotData.data.thankYouScreen.isEnabled) {
                        setThankYouScreenData(leadBotData.data.thankYouScreen)
                    }
                    else {
                        setThankYouScreenData({
                            isEnabled: true,
                            descrption: `<h3 style="text-align: center;">Thank you for contacting us! We will be in touch as soon as we can.</h3>`
                        })
                    }

                    if (welcomeScreen.isEnabled) {
                        setShowWelcomeScreen(true)
                        setShowChatHistoryScreen(false)
                        setShowThankYouScreen(false)
                    } else {
                        onGetStarted()
                    }

                    if (
                        typeof leadBotData.data.customization.isBrandingEnabled ===
                        'boolean' &&
                        !leadBotData.data.customization.isBrandingEnabled
                    ) {
                        setShowBranding(false)
                    } else {
                        setShowBranding(true)
                    }

                    setLoading(false)
                } else {
                    setLoading(false)
                    setIsActive(false)
                }
            } catch (err) {
                setLoading(false)
                setIsActive(false)
                console.log('err', err)
            }
        }
        if (leadBotId) fetchData()
    }, [leadBotId])

    useEffect(() => {
        async function geoLocation() {
            try {
                const leadBotData = await getGeoLocation()
                if (leadBotData.country_code) {
                    setCountryCode(leadBotData.country_code)
                }
            } catch (err) {
                console.log(err)
            }
        }
        geoLocation()
    }, [])

    const onGetStarted = () => {
        setShowWelcomeScreen(false)
        setShowChatHistoryScreen(true)
        setShowThankYouScreen(false)
    }

    const updateResponse = (input, question, extra, newResponse) => {
        if (!question) return newResponse;
        if (_.isEmpty(question.attributeData)) return newResponse;
        const customResponse = { ...newResponse };

        const keyname = getAPIKeyName(question);

        const attribute = question.attributeData;

        switch (attribute.inputType) {
            case attributeTypes.TEXT:
            case attributeTypes.NUMBER:
            case attributeTypes.CURRENCY:
            case attributeTypes.RATING:
            case attributeTypes.DOMAIN:
            case attributeTypes.FILE:
            case attributeTypes.AI_WIZARD:
            case attributeTypes.EMAIL_ADDRESS:
            case attributeTypes.PHONE_NUMBER:
            case attributeTypes.RECORD_REFERENCE:
            case attributeTypes.ACTOR_REFERENCE:
            case attributeTypes.CHECKBOX:
                if (attribute.isMultiSelect) customResponse[`${keyname}`] = [input];
                else customResponse[`${keyname}`] = input;
                break;
            case attributeTypes.DATE:
            case attributeTypes.TIMESTAMP:
                if (attribute.isMultiSelect) customResponse[`${keyname}`] = [new Date(input).getTime()];
                else customResponse[`${keyname}`] = new Date(input).getTime();
                break;
            case attributeTypes.STATUS:
            case attributeTypes.SELECT:
            case attributeTypes.MULTISELECT:
                if (attribute.isMultiSelect) {
                    if (Array.isArray(input)) {
                        customResponse[`${keyname}`] = input.map((each) => each._id);
                    }
                    else customResponse[`${keyname}`] = input ? [input._id] : [];
                }
                else {
                    if (Array.isArray(input)) customResponse[`${keyname}`] = input[0]._id;
                    else customResponse[`${keyname}`] = input ? input._id : '';
                }
                break;
            default:
                if (attribute.isMultiSelect) customResponse[`${keyname}`] = [input];
                else customResponse[`${keyname}`] = input;
                break;
        }
        return customResponse
    }

    const allVisibleQuestions = questions.filter((ques) => !ques.isHidden)

    const handleChange = (key, value) => {
        setFormData((prev) => ({ ...prev, [key]: value }))
    }

    const getAPIKeyName = (question) => {
        let keyName = ''
        for (const each of question.path) {
            if (keyName) keyName += '_'
            keyName += each.attributeId;
        }
        return keyName;
    }

    const handleFormSubmit = async () => {
        // form validation
        const formValidationData = allVisibleQuestions.reduce((acc, question) => {
            const apiKeyName = getAPIKeyName(question);
            const value = formData[apiKeyName]
            const validationResult = validate([question], 0, value);
            if (validationResult.message) {
                return {
                    firstErrorAt: acc.firstErrorAt || apiKeyName,
                    results: { ...acc.results, [apiKeyName]: validationResult.message },
                }
            }
            return acc
        },
            { firstErrorAt: '', results: {} })

        const FormFirstErrorAt = formValidationData.firstErrorAt
        const formValidationResults = formValidationData.results

        const errorElement = document.querySelector(`#question-${FormFirstErrorAt}`)
        if (errorElement) {
            errorElement.scrollIntoView({ behavior: 'smooth' })
        }

        setErrorData(formValidationResults)
        if (!_.isEmpty(formValidationResults)) return

        let formattedPayload = {};
        for (const ques of questions) {
            if (ques.isHidden) {
                const value = ques.defaultAnswer;
                formattedPayload = updateResponse(value, ques, '', formattedPayload)
            }
            else {
                const apiKeyName = getAPIKeyName(ques);
                const value = formData[apiKeyName];
                formattedPayload = updateResponse(value, ques, '', formattedPayload)
            }
        }

        saveResponse(leadBotId, formattedPayload)
        if (thankYouScreenData.isEnabled) {
            setShowWelcomeScreen(false)
            setShowChatHistoryScreen(false)
            setShowThankYouScreen(true)
        }
    }


    return <ThemeProvider theme={theme}>
        {loading ? <Loading />
            : !isActive ? <NotAvailable /> :
                (showWelcomeScreen || showChatHistoryScreen) ? <NormalFormContainer>
                    <NFViewContainer>
                        <FormDemo
                            welcomeScreenData={welcomeScreenData}
                            questions={allVisibleQuestions}
                            formData={formData}
                            countryCode={countryCode}
                            handleChange={handleChange}
                            customisation={customisation}
                            handleFormSubmit={handleFormSubmit}
                            errorData={errorData}
                            theme={theme}
                        />
                        {showBranding && <>
                            <PoweredByWrapper>
                                Powered by <PoweredByA target='_blank' rel="noopener noreferrer" href="https://zixflow.com/">Zixflow</PoweredByA>
                            </PoweredByWrapper></>}
                    </NFViewContainer>
                </NormalFormContainer> :
                    showThankYouScreen ?
                        <ThankYouScreen showBranding={showBranding} data={thankYouScreenData} /> : null}
    </ThemeProvider>
}
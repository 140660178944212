import _ from "lodash";
import React, { useEffect } from "react";
import styled from "styled-components";

export default function FormInput({ attribute, value, setValue, type, onKeyDown, question }) {
  // if (attribute.isMultiSelect) {
  //   return <MultiFormInput attribute={attribute} value={value} setValue={setValue} type={type} onKeyDown={onKeyDown} question={question}/>
  // }
  return <SingleFormInput attribute={attribute} value={value} setValue={setValue} type={type} onKeyDown={onKeyDown} question={question} />
}

function SingleFormInput({ attribute, value, setValue, type, onKeyDown, question }) {
  return <InputInner>
    <Input
      type={type}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={onKeyDown}
      placeholder={`${question}`}
    />
  </InputInner>
}

function MultiFormInput({ attribute, value, setValue, type, onKeyDown, question }) {
  const [textValue, setTextValue] = React.useState([""]);
  const [showAddButton, setShowAddButton] = React.useState(false);

  useEffect(() => {
    if (_.isArray(value)) {
      setTextValue(value);
    }
    else if (!_.isArray(value) && value) {
      setTextValue([value]);
    }
    else {
      setTextValue([""]);
    }
  }, [value]);

  useEffect(() => {
    for (let i = 0; i < textValue.length; i++) {
      if (!textValue[i]) {
        setShowAddButton(false);
        return;
      }
    }
    setShowAddButton(true);
  }, [textValue]);

  const handleAddNew = () => {
    for (let i = 0; i < textValue.length; i++) {
      if (!textValue[i]) {
        return false;
      }
    }
    const newValue = [...textValue];
    newValue.push("");
    setValue(newValue);
  }

  const handleRemove = (index) => {
    if (textValue.length === 1) return;
    const newValue = textValue.filter((text, i) => i !== index);
    setValue(newValue);
  }

  const handleChange = (index, value) => {
    const newValue = [...textValue];
    newValue[index] = value;
    setValue(newValue);
  }

  return <div>
    <MultiInputHolder>
      {textValue.map((text, index) => {
        return <InputInner key={index}>
          <Input
            type={type}
            value={text}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={onKeyDown}
            placeholder={`${question}`}
          />
          {textValue.length > 1 && <InputCancelButton onClick={() => handleRemove(index)}>X</InputCancelButton>}
        </InputInner>
      })}
    </MultiInputHolder>
    {showAddButton && <AddButton onClick={handleAddNew}>+ Add {question}</AddButton>}
  </div>
}

const InputInner = styled.div`
  display: flex;
  flex: 1 1 0%;
  box-sizing: inherit;
`;
const Input = styled.input`
  color: #232529;
  font-size: 1rem;
  font-family: inherit;
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #e2e8f0;
  border-radius: 3px;
  line-height: 1rem;
  outline: none;
  transition: all 0.3s ease 0s;
  box-shadow: 0px 0px 1px 1px #e2e8f094;

  &:focus {
    border: 1px solid ${props => props.theme.answer};
    box-shadow: 0px 0px 1px 1px ${props => props.theme.answer};;
  }
`;

const MultiInputHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 4px;
  gap: 10px;
`;

const AddButton = styled.button`
  color: #525252;
  outline: none;
  border: none;
  background: none;
  font-size: 0.85rem;

  &:hover, &:focus {
    cursor: pointer;
    color: ${props => props.theme.answer};
  }
`;

const InputCancelButton = styled.button`
  color: #232529;
  font-size: 0.8rem;
  font-family: inherit;
  padding: 10px 15px;
  border: 1px solid #e2e8f0;
  border-radius: 3px;
  line-height: 1rem;
  outline: none;
  transition: all 0.3s ease 0s;
  box-shadow: 0px 0px 1px 1px #e2e8f094;
  background: none;

  &:hover {
    color: red;
    border: 1px solid ${props => props.theme.answer};
    box-shadow: 0px 0px 1px 1px ${props => props.theme.answer};;
  }
`;
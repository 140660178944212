export const getLeadbotDetails = (id) => {
  return new Promise((resolve, reject) => {
    fetch(`/leadbot-api/widget/v2/${id}`)
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
  })
}

export const saveResponse = (id, data) => {
  console.log('saveResponse', id, JSON.stringify(data))
  fetch(`/leadbot-api/widget/v2/${id}`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        console.log(result)
      },
      (error) => {
        console.log(error)
      }
    )
}
export const getGeoLocation = () => {
  return new Promise((resolve, reject) => {
    fetch('https://ipapi.co/json')
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
  })
}

export const getGeoLocationByRangerAPI = () => {
  return new Promise((resolve, reject) => {
    fetch('https://ranger-api.appit.cyou/api/v1.0.4/track')
      .then((res) => res.json())
      .then(
        (result) => {
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
  })
}

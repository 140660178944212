import React from "react";
import {
    WelcomeScreenWrapper, WSButtonWrapper, WSContentWrapper, WSImageContainer, WSImageContainer2, WSImage, WSContentWrapper1,
    WSContentWrapper2, WsButtonContainer,
    WsButton, WSDummyButton
} from "./styled";

export default function WelcomeScreen(props) {
    return <WelcomeScreenWrapper>
        {props.data.mediaUrl && <WSImageContainer>
            <WSImageContainer2>
                <WSImage src={props.data.mediaUrl} />
            </WSImageContainer2>
        </WSImageContainer>}
        <WSContentWrapper isImageContainer={Boolean(props.data.mediaUrl)}>
            <WSContentWrapper1>
                <WSContentWrapper2>
                    <span className="content-wrapper" dangerouslySetInnerHTML={{ "__html": props.data.descrption }}></span>
                </WSContentWrapper2>
            </WSContentWrapper1>
            <WSDummyButton />
            <WSButtonWrapper>
                <WsButtonContainer>
                    <WsButton onClick={props.onGetStarted}>{props.data.buttonText}</WsButton>
                </WsButtonContainer>
            </WSButtonWrapper>
        </WSContentWrapper>
    </WelcomeScreenWrapper>
}
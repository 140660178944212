import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import Launcher from "./components/Launcher";
import * as RTG from "react-transition-group";
import Bot from "./components/Bot";
import { subscribeToEvents, unSubscribedToEvent } from "./util";
import { ZixWAdataValidator } from "./util";
import "./widget/index.css";

const sendMessage = (params) => {
    window.parent.postMessage(
        {
            fromSalesSimplify: true,
            source: "fromMessenger",
            ...params,
        },
        '*'
    );
};

export default function Messanger(props) {
    const [isMessengerVisible, setMessengerVisible] = useState(false);
    const [showNotifier, setShowNotifier] = useState(true);
    const [introBubble, setIntroBubble] = useState("");
    const [options, setOptions] = useState({
        // brandColor: "#22C065",
        // ctaText: "Chat on WhatsApp",
        // position: "left",
        // brandName: "Zixflow",
        // phoneNumber: "",
        // brandHeadline: "Hi 👋 Need help or have questions? Let's chat on WhatsApp!",
        // messageText: "{{page_link}}Hello, %0A I have a question a{{page_title}}bout {{page_link}}",
        // autoShow: true,
        // brandImg: "https://res.cloudinary.com/sales-simplify/image/upload/v1722341062/zixflowicon_h17kdj.png"
    });


    const EventHandler = useCallback((event) => {
        if (event.data.fromPublisher) {
            if (event.data.action === "showMessenger") {
                if (event.data.setting) {
                    const validate = ZixWAdataValidator(event.data.setting);
                    if (!validate) return;
                    setOptions(event.data.setting);
                    setIntroBubble(event.data.setting.ctaText);
                    sendMessage({
                        isVisible: true,
                        message: "notifier",
                        isSmallContainer: true
                    })
                }
            }
        }
    }, []);

    useEffect(() => {
        bindWindowEvent(window, "message", EventHandler)

        return () => {
            unSubscribedToEvent(window, "message", EventHandler)
        };
    }, [EventHandler])

    const bindWindowEvent = (element, eventName, eventHandler) => {
        subscribeToEvents(element, eventName, eventHandler);
    }


    const handlerLauncherClick = (currentState) => {
        setMessengerVisible(!currentState);
        setShowNotifier(false);

        sendMessage({
            isVisible: !currentState,
            message: "messenger",
            isSmallContainer: currentState
        });
    }
    const handlerNotifierOff = () => {
        setShowNotifier(false);
        sendMessage({
            isVisible: false,
            message: "notifier",
            isSmallContainer: true
        });
    }
    const onContentClick = () => {
        setMessengerVisible(!isMessengerVisible);
        setShowNotifier(false);

        sendMessage({
            isVisible: !isMessengerVisible,
            message: "messenger",
            isSmallContainer: isMessengerVisible
        });
    }


    const renderLauncher = () => {
        return <Launcher
            isMessengerVisible={isMessengerVisible}
            onClick={handlerLauncherClick}
            onContentClick={onContentClick}
            uiOptions={{
                color: options.brandColor,
                logo: ""
            }}
            showNotifier={showNotifier}
            introBubble={introBubble}
            setShowNotifier={handlerNotifierOff}

        />
    }

    if (_.isEmpty(options)) return null;


    return <React.StrictMode>
        <div className="zixflow-widget">
            <RTG.CSSTransition
                in={isMessengerVisible}
                timeout={300}
                classNames="scale-in"
                unmountOnExit={true}
            >
                <div className="zixflow-wa-messenger">
                    <div className="zixflow-conversation-detail">
                        <Bot options={options} setMessengerVisible={setMessengerVisible}/>
                    </div>
                </div>
            </RTG.CSSTransition>
            {renderLauncher()}
        </div>
    </React.StrictMode>
};
import styled from "styled-components";
import { hexToLuma, setOpacity } from "../widget/helper";

export const WelcomeScreenWrapper = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  width: 100%;
  padding: 0px;
  z-index: 1;
  @media (min-width:10px )  { 
    display: table-cell;
    flex-direction: column;
  }
  @media (min-width:801px)  { 
    display: flex;
    flex-direction: row;
  }
`;

export const WSButtonWrapper = styled.div`
  height: 70px;
  border-radius: 4px;
  background: ${props => setOpacity(props.theme.question, 0.1)};
  position: absolute;
  bottom: 0;
  left:0;
  width: calc(100% - 20px);
  margin: 10px;
  box-sizing: border-box;
`;

export const WSDummyButton = styled.div`
  width: 100%;
  height: 90px;
  border-radius: 4px;
`;

export const WSContentWrapper = styled.div`
  position: relative;
  width: 50%;
  overflow-y: auto;
  height: 100%;
  display: flex;
  justify-content : center;
  align-items:center;
  flex-direction: column;
  box-sizing: border-box;
  padding:10px;
  @media (min-width:10px )  { 
    width: calc(100%);
    height: ${props => props.isImageContainer ? "70%" : "100%"};
  }
  @media (min-width:801px)  { 
    height: 100%;
    width: ${props => props.isImageContainer ? "50%" : "50%"};

  }
`;

export const WSImageContainer = styled.div`
  position: relative;
  display: flex;
  width:50%;
  height: 100%;
  transform-origin: left bottom;
  animation: 400ms ease-out 0s 1 normal none running eMWxiG;
  @media (min-width:10px )  { 
    width: 100%;
    height: 30%;
  }
  @media (min-width:801px)  { 
    height: 100%;
    width: 50%;
  }
`;

export const WSImageContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 20px;
  transition: opacity 0.2s ease 0s;
  overflow-wrap: break-word;
  width:100%;
  height:100%;
  background: transparent;
  color: ${props => hexToLuma(props.theme.question)};
`;
export const WSImage = styled.img`
  display: block;
  // width: inherit;
  // height: inherit;
  background: transparent;
  // display: block;
  margin: 0px auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 0%;
`;

export const WSContentWrapper1 = styled.div`
  position: relative;
  display: flex;
  padding: 20px;
  width: 100%;
  overflow-y:auto;
  max-height: calc(100% - 70px);
  transform-origin: left bottom;
  animation: 400ms ease-out 0s 1 normal none running eMWxiG;
  @media (min-width:10px )  { 
    padding:0px;
  }
  @media (min-width:801px)  { 
    padding: 20px;
  }
`;
export const WSContentWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px 24px 24px 4px;
  font-size: 16px;
  line-height: 20px;
  transition: opacity 0.2s ease 0s;
  overflow-wrap: break-word;
  // max-width: calc(100% - 45px);
  color: ${props => props.theme.question};
  padding: 12px 16px;
  width:100%;
`;

export const WsButtonContainer = styled.div`
  margin: 0px 40px;
  height: 100%;
  // @media (min-width:10px )  { 
    display:flex;
    justify-content: center;
    align-items:center;
  // }
  // @media (min-width:801px)  { 
  //   display:flex;
  //   justify-content: flex-start;
  //   align-items:center;
  // }
`;
export const WsButton = styled.button`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px;
  padding: 0px 12px;
  line-height: 20px;
  min-height: 44px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 500;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px 0px;
  transition: all 0.2s ease 0s;
  text-align: center;
  cursor: pointer;
  background: ${props => props.theme.button};
  border: 1px solid ${props => props.theme.button};
  color: ${props => hexToLuma(props.theme.button)};
  outline: 0px;

  &:hover{
    opacity: 0.75;
  }
`;
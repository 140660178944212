import React from 'react'
import ChatWidget from './widget'
import Messenger from './messenger/messenger'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { BrowserRouter as Router, useRoutes } from 'react-router-dom'
// import NormalformWidget from './normal-form'
import NormalFormV2 from './normal-form-2'
import ModernformWidget from './widget/modernform-widget'
import WaBot from './wa-bot/wa-bot'

const App = () => {
  const routes = useRoutes([
    { path: '/c/:id', element: <ChatWidget /> },
    { path: '/embed/:id', element: <ChatWidget /> },
    { path: '/leadbot', element: <Messenger /> },
    { path: '/m/:id', element: <ModernformWidget /> },
    // { path: '/n/:id', element: <NormalformWidget /> },
    { path: '/n/:id', element: <NormalFormV2 /> },
    { path: '/wa', element: <WaBot /> },
  ])
  return routes
}
export function BaseRoutes() {
  return (
    <Router>
      <App />
    </Router>
  )
}

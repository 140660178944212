import styled from 'styled-components'
import { hexToLuma } from './helper'

export const WidgetContainer = styled.div`
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 16px;
  height: 100%;
  width: 100%;
`

export const Conversation = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`

export const AppFrame = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 700px;
  margin: 0px auto;
  padding-top: 16px;
  color: rgb(76, 76, 76);
  background: transparent;
  border-radius: 3px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  z-index: 1;
`

export const AppBackground = styled.div`
  display: inline-block;
  line-height: 0;
  opacity: 1;
  background-color: ${(props) => props.theme.background};
  background-image: url(${(props) => props.theme.backgroundImage});
  background-position: center top;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
  }
`

export const ConversationMessageList = styled.div`
  position: relative;
  width: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding: 0px 16px 16px;
  box-sizing: inherit;
`

export const RootMessageList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const MessageListScroller = styled.div`
  overflow-y: auto;
  height: 100%;
`

export const MessageListWrapper = styled.div`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column-reverse;
  min-height: 100%;
  overflow: hidden;
`

export const MessageContainer = styled.div`
  position: relative;
  opacity: 1;
  transition: opacity 450ms ease-out 0s;
  box-sizing: inherit;
`

export const AvatarWrapper = styled.figure`
  position: absolute;
  bottom: 5px;
  margin: 0px;
  opacity: 1;
  box-sizing: inherit;
  color: ${(props) => props.theme.question};
`

export const QuestionWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 0%;
  padding: 4px 0px 4px 48px;
  transform-origin: left bottom;
  animation: 400ms ease-out 0s 1 normal none running eMWxiG;
  box-sizing: inherit;
`
export const InnerQuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  font-size: 16px;
  line-height: 20px;
  transition: opacity 0.2s ease 0s;
  overflow-wrap: break-word;
  max-width: 260px;
  background: ${(props) => props.theme.question};
  color: ${(props) => hexToLuma(props.theme.question)};
  padding: 12px 16px;
`

export const AnswerWrapper = styled.div`
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  transform-origin: right bottom;
  animation: 400ms ease-out 0s 1 normal none running eMWxiG;
`
export const InnerAnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 24px 24px 4px;
  font-size: 16px;
  line-height: 20px;
  transition: opacity 0.2s ease 0s;
  overflow-wrap: break-word;
  max-width: 260px;
  margin: 32px 24px 32px 0px;
  padding: 12px 16px;
  color: ${(props) => props.theme.question};
  border: 1px solid ${(props) => props.theme.question};
`

export const ErrorMessageWrapper = styled.div`
  padding: 12px;
`

export const ErrorMessage = styled.div`
  padding: 0px 12px 0px 12px;
  line-height: 40px;
  font-size: 13px;
  background: #fff;
  color: #d63031;
`

export const ModernForm = styled.form`
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 600px) {
    padding: 0 2%;
  }
`

export const FormBox = styled.div`
  transition: 0.4s ease-in-out;
  height: 0vh;
  opacity: 0;
  display: flex;
  // align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  ${({ active }) =>
    active &&
    `height: 100vh;
      opacity: 1;`}
`

export const MoveButton = styled.button`
  background: ${(props) => props.theme.button};
  border: 1px solid ${(props) => props.theme.button};
  color: ${(props) => hexToLuma(props.theme.button)};
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  width: 35px;
  height: 30px;
  &:disabled i {
    opacity: 0.7;
  }
`

export const MoveButtonsWrapper = styled.div`
  border-radius: 4px;
  overflow: hidden;
  position: fixed;
  bottom: 10px;
  right: 40px;
  display: flex;
  ${MoveButton}:first-child {
    border-right: 2px solid #041e2e4d;
  }
`

export const QuestionNumber = styled.span`
  color: ${(props) => props.theme.answer};
  font-size: 1rem;
`

export const Question = styled.span`
  font-size: 1.4rem;
`

import React from "react";
import { attributeTypes } from "../footer/constants";
// import FormInput from "../../containers/normal-form-2/components/form/text-field";
// import CheckboxInput from "../../containers/normal-form-2/components/form/checkbox";
// import CurrencyInput from "../../containers/normal-form-2/components/form/currency";
// import RatingInput from "../../containers/normal-form-2/components/form/rating";
// import SelectInput from "../../containers/normal-form-2/components/form/select";
// import PhoneInputForm from "../../containers/normal-form-2/components/form/phone";
// import StatusSelect from "../../containers/normal-form-2/components/form/status";
import FormInput from "./input";
import RatingInput from "./rating";
import SelectInput from "./select";
import PhoneInputForm from "./phone";
import StatusSelect from "./status";
import CurrencyInput from "./currency";
import CheckboxInput from "./checkbox";
import MultiSelectInput from "./multi-select";


export default function CustomInput({ attribute, value, setValue, onKeyDown, countryCode }) {

    switch (attribute.inputType) {
        case attributeTypes.TEXT:
            return <FormInput attribute={attribute} value={value} setValue={setValue} type={"text"} onKeyDown={onKeyDown} />
        case attributeTypes.NUMBER:
            return <FormInput attribute={attribute} value={value} setValue={setValue} type={"number"} onKeyDown={onKeyDown} />
        case attributeTypes.CHECKBOX:
            return <CheckboxInput attribute={attribute} value={value} setValue={setValue} type={"number"} onKeyDown={onKeyDown} />
        case attributeTypes.CURRENCY:
            return <CurrencyInput attribute={attribute} value={value} setValue={setValue} onKeyDown={onKeyDown} />
        case attributeTypes.DATE:
            return <FormInput attribute={attribute} value={value} setValue={setValue} type={"date"} onKeyDown={onKeyDown} />
        case attributeTypes.TIMESTAMP:
            return <FormInput attribute={attribute} value={value} setValue={setValue} type={"datetime-local"} onKeyDown={onKeyDown} />
        case attributeTypes.RATING:
            return <RatingInput attribute={attribute} value={value} setValue={setValue} onKeyDown={onKeyDown} />
        case attributeTypes.STATUS:
            return <StatusSelect attribute={attribute} value={value} setValue={setValue} onKeyDown={onKeyDown} />
        case attributeTypes.SELECT:
            if (attribute.isMultiSelect) {
                return <MultiSelectInput attribute={attribute} value={value} setValue={setValue} onKeyDown={onKeyDown} />;
            }
            return <SelectInput attribute={attribute} value={value} setValue={setValue} onKeyDown={onKeyDown} />
        case attributeTypes.MULTISELECT:
            return <MultiSelectInput attribute={attribute} value={value} setValue={setValue} onKeyDown={onKeyDown} />
        case attributeTypes.DOMAIN:
            return <FormInput attribute={attribute} value={value} setValue={setValue} type={"text"} onKeyDown={onKeyDown} />
        case attributeTypes.FILE:
            return <FormInput attribute={attribute} value={value} setValue={setValue} type={"text"} onKeyDown={onKeyDown} />
        case attributeTypes.AI_WIZARD:
            return <FormInput attribute={attribute} value={value} setValue={setValue} type={"text"} onKeyDown={onKeyDown} />
        case attributeTypes.EMAIL_ADDRESS:
            return <FormInput attribute={attribute} value={value} setValue={setValue} type={"text"} onKeyDown={onKeyDown} />
        case attributeTypes.PHONE_NUMBER:
            return <PhoneInputForm attribute={attribute} value={value} setValue={setValue} onKeyDown={onKeyDown} countryCode={countryCode} />
        default:
            return <div></div>;
    }
}